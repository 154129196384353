export const DOCUMENT_POPUP_IMAGES = {
  b: $cdn() + '/fe/popup-document-test/popup-document-b.png',
  c: $cdn() + '/fe/popup-document-test/popup-document-c.png',
};

export const DOCUMENT_POPUP_FILES = [
  {
    fileUrl:
      $cdn() +
      '/fe/popup-document-test/%E1%84%89%E1%85%B5%E1%86%AF%E1%84%89%E1%85%AE%E1%84%85%E1%85%A7%E1%86%BC+250%E1%84%86%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%8B%E1%85%B5%E1%84%85%E1%85%A1%E1%84%86%E1%85%A7%E1%86%AB+%E1%84%8C%E1%85%A2%E1%84%90%E1%85%A6%E1%84%8F%E1%85%B3+%E1%84%8B%E1%85%B5%E1%84%85%E1%85%A5%E1%87%82%E1%84%80%E1%85%A6+%E1%84%92%E1%85%A1%E1%84%89%E1%85%A6%E1%84%8B%E1%85%AD!+%E1%84%80%E1%85%A1%E1%84%80%E1%85%A8%E1%84%87%E1%85%AE+3%E1%84%8C%E1%85%A9%E1%86%BC%E1%84%89%E1%85%A6%E1%84%90%E1%85%B3.xlsx',
    fileOrg: '실수령 250만원이라면 필수! 가계부 3종세트.xlsx',
  },
  {
    fileUrl:
      $cdn() +
      '/fe/popup-document-test/%E1%84%8B%E1%85%AF%E1%86%AF%E1%84%87%E1%85%AE+%E1%84%8E%E1%85%AE%E1%84%8E%E1%85%A5%E1%86%AB!+%E1%84%8B%E1%85%B5%E1%86%B0%E1%84%8B%E1%85%B3%E1%84%86%E1%85%A7%E1%86%AB+%E1%84%83%E1%85%A9%E1%86%AB%E1%84%8B%E1%85%B5+%E1%84%83%E1%85%AC%E1%84%82%E1%85%B3%E1%86%AB+%E1%84%80%E1%85%A7%E1%86%BC%E1%84%8C%E1%85%A6%3A%E1%84%8C%E1%85%A2%E1%84%90%E1%85%A6%E1%84%8F%E1%85%B3+%E1%84%83%E1%85%A9%E1%84%89%E1%85%A5+30%E1%84%80%E1%85%AF%E1%86%AB+%E1%84%85%E1%85%B5%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3.xlsx',
    fileOrg: '읽으면 돈이 되는 경제/재테크 도서 30권 리스트.xlsx',
  },
  {
    fileUrl:
      $cdn() +
      '/fe/popup-document-test/%E1%84%82%E1%85%A2%E1%84%8C%E1%85%B5%E1%86%B8%E1%84%86%E1%85%A1%E1%84%85%E1%85%A7%E1%86%AB+%E1%84%8E%E1%85%A6%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%84%89%E1%85%B3%E1%84%90%E1%85%B3.pdf',
    fileOrg: '단계별 내집마련 체크리스트.pdf',
  },
];

export const PRODUCT_DETAIL_BANNER_IMAGES = {
  lectureRecommendation: $cdn() + '/fe/product-detail-banners/lecture-recommendation.png',
  wbStudy: $cdn() + '/fe/product-detail-banners/wb-study.png',
};

// 상페 - 이런 분들은 꼭 들으세요 하드코딩
export const PRODUCT_DETAIL_FOR_WHO: Record<string, string[]> = {
  '3050': [
    '부동산 투자에 관심 있지만 어떻게 시작해야 할지 모르겠는 분',
    '적은 자본으로 시작할 수 있는 부동산 소액투자 방법이 궁금한 분',
    '초보도 실패 없이 안전하게 할수 있는 부동산 투자방법이 궁금한 분',
    '1-2년안에 서울 수도권 투자 기회 잡고 싶은 분',
  ],
  '2368': [
    '평소 스마트스토어, 쿠팡으로 구매를 많이 하시는  분',
    '상품 등록 및 관리 방법을 모르시는 분',
    '경쟁력 있는 상품을 찾고 싶은 분',
    '부수입은 필요하지만 무자본으로 부담없이 시작하고 싶은 분',
  ],

  '2924': [
    '지금보다 딱 100만원만 더 벌고 싶은 분',
    '일주일에 하루 일하고 월급보다 더 버는 삶을 살고 싶은 분',
    '연금처럼 따박따박 자동 수익을 만들고 싶은 분',
  ],
  '2838': [
    '3천만원 미만으로 5천만원 이상 수익을 내고 싶은 분',
    '일하지 않아도 저절로 불어나는 자본소득 만들고 싶은 분',
    '모인 돈이 적어 미래가 막막한 직장인, 주부',
  ],
};

// 상페 - 이런 내용을 배울 수 있어요 하드코딩
export const PRODUCT_DETAIL_LEARN_WHAT: Record<string, string[]> = {
  '3050': [
    '지금 사면 돈 버는 지역과 아파트 찾는 방법',
    '적은 자본으로 안전하게 시작하는 부동산 투자 방법',
    '내가 보는 아파트 가격이 싼지 비싼지 확인하는 방법',
    '24년 서울·수도권·지역별 부동산 투자 전략',
  ],
  '2368': [
    '스마트스토어 초기 설정방법',
    '잘 팔리는 상품 소싱법',
    '컴맹도 쉽게 만드는 상세페이지 노하우',
    '미리 알고 대비하는 세금&지적재산권',
  ],

  '2924': [
    '자동 수익의 시작, 애드센스 승인 받는 법',
    '돈 되는 주제 찾고 키워드 활용하는 법',
    '초보도 글 하나로 고수익 내는 법',
  ],
  '2838': [
    '3천 미만으로 투자해 2배 벌 수 있는 아파트 투자법',
    '어떤 아파트를 언제 얼마에 사야 하는지 판단하는 기준',
    '소액투자 단계별 to-do list 및 체크 리스트',
  ],
};

export enum ProductCategorySeq {
  WEOLBU_STUDY_ZERO = '118',
  WEOLBU_STUDY_TR_RP = '139',
}

export enum DisplayProductCategorySeq {
  COACHING = '14',
  DONDOKMO = '18',
}

export const REVIEW_TYPE = {
  RECENTNESS: 'RECENTNESS',
  RECOMMENDATION: 'RECOMMENDATION',
};

export type ReviewType = keyof typeof REVIEW_TYPE;

export const LECTURE_ACHIEVEMENT = {
  '3236': {
    title: '수강생들의 성과를 확인해보세요!',
    contents: [
      {
        nickname: '초심으로',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_7.webp',
        image: $cdn() + '/fe/lecture-achievement/3236/img_7_b.webp',
      },
      {
        nickname: '위니빈센트',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_8.webp',
        image: $cdn() + '/fe/lecture-achievement/3236/img_8_b.webp',
      },
      {
        nickname: '옐로제이',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_10.webp',
        image: $cdn() + '/fe/lecture-achievement/3236/img_10_b.webp',
      },
      {
        nickname: '주바라기',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_9.webp',
        image: $cdn() + '/fe/lecture-achievement/3236/img_9_b.webp',
      },
      {
        nickname: '레이마암',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_1.png',
        image: $cdn() + '/fe/lecture-achievement/3236/img_1_b.png',
      },
      {
        nickname: '표현옹',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_2.png',
        image: $cdn() + '/fe/lecture-achievement/3236/img_2_b.png',
      },
      {
        nickname: '먼데이',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_3.png',
        image: $cdn() + '/fe/lecture-achievement/3236/img_3_b.png',
      },
      {
        nickname: '딸둘맘v',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_4.png',
        image: $cdn() + '/fe/lecture-achievement/3236/img_4_b.png',
      },
      {
        nickname: '냉동생선',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_5.png',
        image: $cdn() + '/fe/lecture-achievement/3236/img_5_b.png',
      },
      {
        nickname: '꿀모닝이',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3236/img_6.png',
        image: $cdn() + '/fe/lecture-achievement/3236/img_6_b.png',
      },
    ],
  },
  '3491': {
    title: '수강생들의 성과를 확인해보세요!',
    contents: [
      {
        nickname: '행자91',
        description: '24년 5월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3491/img_1.png',
        image: $cdn() + '/fe/lecture-achievement/3491/img_1_b.png',
      },
      {
        nickname: '김진*',
        description: '24년 5월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3491/img_2.png',
        image: $cdn() + '/fe/lecture-achievement/3491/img_2_b.png',
      },
      {
        nickname: '이유나',
        description: '24년 5월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3491/img_3.png',
        image: $cdn() + '/fe/lecture-achievement/3491/img_3_b.png',
      },
      {
        nickname: '그린T',
        description: '24년 5월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3491/img_4.png',
        image: $cdn() + '/fe/lecture-achievement/3491/img_4_b.png',
      },
    ],
  },
  '3415': {
    title: '수강생들의 성과를 확인해보세요!',
    contents: [
      {
        nickname: '말랑여사',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_1.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_1_b.png',
      },
      {
        nickname: '룽잇',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_2.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_2_b.png',
      },
      {
        nickname: '탈렌',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_3.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_3_b.png',
      },
      {
        nickname: '미아',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_4.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_4_b.png',
      },
      {
        nickname: '다소니킴',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_5.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_5_b.png',
      },
    ],
  },
  '3665': {
    title: '수강생들의 성과를 확인해보세요!',
    contents: [
      {
        nickname: '말랑여사',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_1.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_1_b.png',
      },
      {
        nickname: '룽잇',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_2.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_2_b.png',
      },
      {
        nickname: '탈렌',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_3.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_3_b.png',
      },
      {
        nickname: '미아',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_4.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_4_b.png',
      },
      {
        nickname: '다소니킴',
        description: '24년 6월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3415/img_5.png',
        image: $cdn() + '/fe/lecture-achievement/3415/img_5_b.png',
      },
    ],
  },
  '3270': {
    title: '수강생들의 성과를 확인해보세요!',
    contents: [
      {
        nickname: 'bluebada',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/3270/img_6.webp',
        image: $cdn() + '/fe/lecture-achievement/3270/img_6_b.webp',
      },
      {
        nickname: '10억',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/3270/img_7.webp',
        image: $cdn() + '/fe/lecture-achievement/3270/img_7_b.webp',
      },
      {
        nickname: '깡님',
        description: '24년 4월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3270/img_1.png',
        image: $cdn() + '/fe/lecture-achievement/3270/img_1_b.png',
      },
      {
        nickname: '이상',
        description: '24년 4월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3270/img_2.png',
        image: $cdn() + '/fe/lecture-achievement/3270/img_2_b.png',
      },
      {
        nickname: '문익',
        description: '24년 4월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3270/img_3.png',
        image: $cdn() + '/fe/lecture-achievement/3270/img_3_b.png',
      },
      {
        nickname: '월세받는건물주',
        description: '24년 4월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3270/img_4.png',
        image: $cdn() + '/fe/lecture-achievement/3270/img_4_b.png',
      },
      {
        nickname: '훈이네부동산',
        description: '24년 4월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3270/img_5.png',
        image: $cdn() + '/fe/lecture-achievement/3270/img_5_b.png',
      },
    ],
  },
  '3176': {
    title: '수강생들의 성과를 확인해보세요!',
    contents: [
      {
        nickname: '라라쿠키',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3176/img_1.png',
        image: $cdn() + '/fe/lecture-achievement/3176/img_1_b.png',
      },
      {
        nickname: '바야흐로',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3176/img_2.png',
        image: $cdn() + '/fe/lecture-achievement/3176/img_2_b.png',
      },
      {
        nickname: '에이미',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3176/img_3.png',
        image: $cdn() + '/fe/lecture-achievement/3176/img_3_b.png',
      },
      {
        nickname: '우쥬럽',
        description: '24년 7월 수강생',
        thumbnail: $cdn() + '/fe/lecture-achievement/3176/img_4.png',
        image: $cdn() + '/fe/lecture-achievement/3176/img_4_b.png',
      },
    ],
  },
  '2885': {
    title: '수강생들의 성과를 확인해보세요!',
    contents: [
      {
        nickname: '카르카시',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/2885/img_1.webp',
        image: $cdn() + '/fe/lecture-achievement/2885/img_1_b.webp',
      },
      {
        nickname: '크루제인',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/2885/img_2.webp',
        image: $cdn() + '/fe/lecture-achievement/2885/img_2_b.webp',
      },
      {
        nickname: '탐코',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/2885/img_3.webp',
        image: $cdn() + '/fe/lecture-achievement/2885/img_3_b.webp',
      },
      {
        nickname: '푸파',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/2885/img_4.webp',
        image: $cdn() + '/fe/lecture-achievement/2885/img_4_b.webp',
      },
      {
        nickname: '23학번',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/2885/img_5.webp',
        image: $cdn() + '/fe/lecture-achievement/2885/img_5_b.webp',
      },
      {
        nickname: 'Kimminseo',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/2885/img_6.webp',
        image: $cdn() + '/fe/lecture-achievement/2885/img_6_b.webp',
      },
      {
        nickname: 'LuvSSong',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/2885/img_7.webp',
        image: $cdn() + '/fe/lecture-achievement/2885/img_7_b.webp',
      },
      {
        nickname: 'TOV131',
        description: '',
        thumbnail: $cdn() + '/fe/lecture-achievement/2885/img_8.webp',
        image: $cdn() + '/fe/lecture-achievement/2885/img_8_b.webp',
      },
    ],
  },
};
