import dayjs from 'dayjs';
import { floor } from 'lodash-es';
import { type Res, stripHtml } from 'string-strip-html';

import { safeDateParse } from '~/libs/dateUtils';

import { useCommonStore } from '~/services/common';
import { type SeoData } from '~/services/seo';

import { type ApiResponse, type ApiResponseInner } from '~/types/global';

import { DEFAULT_ORGANIZATION, DEFAULT_PUBLISHER } from './seoConstants';

const seoDefault = {
  title: '당신이 부자가 되는 곳! 월급쟁이부자들',
  description: '월급쟁이부자들의 직장인을 위한 재테크, 부동산 국내 1위 교육 플랫폼. 지금 부자되는 커리큘럼 확인하세요!',
  url: 'https://www.weolbu.com',
  imgUrl: 'https://cdn.weolbu.com/fe/main/og-image.png',
};

export function useSeo() {
  // TODO SEO 메타 데이터 설정에 대한 히스토리 확인 후 로직 개선 필요

  const commonStore = useCommonStore();
  const runtime = useRuntimeConfig();

  function setSeoMeta(seoData: ApiResponse<ApiResponseInner<SeoData>> | null) {
    if (!seoData) {
      useSeoMeta({ title: commonStore.mainSeo?.seoTitle ?? seoDefault.title });
      return;
    }

    const seoTitle: string = seoData?.data?.items?.seoTitle ?? seoDefault.title;
    const seoDescription: string = seoData?.data?.items?.seoDescription ?? seoDefault.description;
    const seoUrl: string = seoData?.data?.items?.seoUrl ?? runtime.public.frontUrl ?? seoDefault.url;
    const seoImgUrl: string = seoData?.data?.items?.seoImgUrl ?? seoDefault.imgUrl;

    useSeoMeta({
      title: () => seoTitle,
      description: () => seoDescription,
      ogTitle: () => seoTitle,
      ogDescription: () => seoDescription,
      ogUrl: () => seoUrl,
      ogImage: () => seoImgUrl,
    });
  }

  const setCommunitySeoMeta = (
    paramId: string | string[],
    title: string | undefined,
    description: string | undefined,
    imgUrl: string | undefined,
  ) => {
    const seoTitle: string = title ?? seoDefault.title;
    const strippedDescription: Res = stripHtml(removeEmojis(description) ?? seoDefault.description);
    const seoDescription: string = strippedDescription.result.slice(0, 60);
    const seoUrl: string = `${runtime.public.frontUrl}/community/${paramId}` ?? seoDefault.url;
    const seoImgUrl: string = imgUrl ?? commonStore.mainSeo?.seoImgUrl ?? seoDefault.imgUrl;

    useSeoMeta({
      title: () => seoTitle,
      description: () => seoDescription,
      ogTitle: () => seoTitle,
      ogDescription: () => seoDescription,
      ogUrl: () => seoUrl,
      ogImage: () => seoImgUrl,
    });
  };

  const setStructuredItemList = (seoData) => {
    useHead({
      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'ItemList',
            itemListElement: seoData.map((data, index) => {
              return {
                '@type': 'SiteNavigationElement',
                position: index + 1,
                name: data.title,
                url: data.linkUrl,
                // description 에 대한 서버 처리 적용 필요
                // description: '집 사기 전 월부 강의. 내집마련부터 부동산투자 기초, 실전 투자 노하우',
              };
            }),
          }),
        },
      ],
    });
  };

  const setBreadcrumbList = ({ currentCategory, categoryList, productList = [] }) => {
    useHead({
      script: [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'CollectionPage',
            '@id': `https://weolbu.com${currentCategory.to}`,
            url: `https://weolbu.com${currentCategory.to}`,
            alternativeHeadline: `${currentCategory.label} | 월급쟁이부자들`,
            specialty: `${currentCategory.label} | 월급쟁이부자들`,
            breadcrumb: {
              '@type': 'BreadcrumbList',
              itemListElement: categoryList.map((category, index) => {
                return {
                  '@type': 'ListItem',
                  position: index + 1,
                  name: `${category.label} | 월급쟁이부자들` || `${category?.cateName} | 월급쟁이부자들`,
                  item: `https://weolbu.com${category.to}`,
                };
              }),
            },
            ...(productList.length > 0 && {
              mainEntity: {
                '@type': 'ItemList',
                itemListElement: productList.map((product, index) => {
                  return {
                    '@type': 'ListItem',
                    position: index + 1,
                    '@id': `https://weolbu.com/product?displaySeq=${product.displaySeq}`,
                    url: `https://weolbu.com/product?displaySeq=${product.displaySeq}`,
                  };
                }),
              },
            }),
          }),
        },
      ],
    });
  };

  const setProdcutDetail = (seoData, productDetailData, bestReviewData, reviewData) => {
    const title: string = seoData?.data?.items?.seoTitle ?? seoDefault.title;
    const description: string = seoData?.data?.items?.seoDescription ?? seoDefault.description;

    // 강사 리스트 - classCreatorList
    // 리뷰 정보 - reviewPoint / reviewCount
    const {
      displayThumbnailUrl,
      previewUrl,
      classCreatorList,
      reviewPoint,
      reviewCount,
      discountPrice,
      courseStartDate,
      courseEndDate,
      firstDiscountPrice,
      secondDiscountPrice,
      displaySeq,
      classCategoryList,
      classCurriculumList,
    } = productDetailData;
    const currentCategory = classCategoryList![0];
    const mergedReviewData = [...bestReviewData, ...reviewData];

    const hasValidReviews = reviewCount > 0 && parseFloat(reviewPoint) > 0;
    const formatDuration = (seconds) => {
      const isoString = new Date(seconds * 1000).toISOString().substr(11, 8);
      const [hours, minutes, sec] = isoString.split(':');
      return `PT${parseInt(hours, 10)}H${parseInt(minutes, 10)}M${parseInt(sec, 10)}S`;
    };
    const totalPlayTimeString = computed(() => {
      if (!classCurriculumList) {
        return 0;
      }
      const totalSeconds = classCurriculumList.map((item) => item.playTime).reduce((acc, cur) => acc + cur, 0);
      return formatDuration(totalSeconds);
    });

    useHead({
      script: [
        // Course 정보 링크 - https://schema.org/Course
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Course',
            name: `${title} | 월급쟁이부자들`,
            description,
            publisher: DEFAULT_PUBLISHER,
            provider: DEFAULT_ORGANIZATION,
            isAccessibleForFree: false,
            image: [displayThumbnailUrl],
            ...(previewUrl && {
              video: {
                '@type': 'VideoObject',
                name: `${title} 미리보기 영상`,
                description,
                contentUrl: previewUrl,
                thumbnailUrl: displayThumbnailUrl,
                uploadDate: courseStartDate,
              },
            }),
            inLanguage: 'ko',
            hasCourseInstance: [
              {
                '@type': 'CourseInstance',
                courseMode: 'Online',
                offers: {
                  '@type': 'Offer',
                  category: 'Paid',
                  url: `https://weolbu.com/product?displaySeq=${displaySeq}`,
                  availability: 'https://schema.org/OnlineOnly',
                  priceCurrency: 'KRW',
                  price: firstDiscountPrice || discountPrice,
                },
                // ISO_8601 기간 표기법 (관련 링크 - https://en.wikipedia.org/wiki/ISO_8601#Durations)
                courseWorkload: totalPlayTimeString.value,
                ...(classCreatorList.length > 0 && {
                  instructor: classCreatorList.map((creator) => {
                    return {
                      '@type': 'Person',
                      name: creator.userName,
                    };
                  }),
                }),
              },
            ],
            offers: {
              '@type': 'Offer',
              category: 'Paid',
              url: `https://weolbu.com/product?displaySeq=${displaySeq}`,
              availability: 'https://schema.org/OnlineOnly',
              priceCurrency: 'KRW',
              price: firstDiscountPrice || discountPrice,
              priceValidUntil: courseEndDate,
            },
            ...(hasValidReviews && {
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: reviewPoint,
                reviewCount,
              },
            }),
            ...(mergedReviewData.length > 0 && {
              review: mergedReviewData.map((review) => {
                return {
                  '@type': 'Review',
                  author: {
                    '@type': 'Person',
                    name: review?.nickName || review?.userName,
                  },
                  reviewBody: stripHtml(review?.reviewText || '').result,
                  datePublished: review?.regDate,
                  reviewRating: {
                    '@type': 'Rating',
                    bestRating: 5,
                    worstRating: 1,
                    ratingValue: Number(review?.reviewPoint),
                  },
                };
              }),
            }),
          }),
        },
        // Product 정보 링크 - https://schema.org/Product
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: `${title} | 월급쟁이부자들`,
            description,
            offers: {
              '@type': 'Offer',
              category: 'Paid',
              url: `https://weolbu.com/product?displaySeq=${displaySeq}`,
              availability: 'https://schema.org/OnlineOnly',
              priceCurrency: 'KRW',
              price: firstDiscountPrice || discountPrice,
            },
            publisher: DEFAULT_PUBLISHER,
            provider: DEFAULT_ORGANIZATION,
            image: [displayThumbnailUrl],
            ...(previewUrl && {
              video: {
                '@type': 'VideoObject',
                name: `${title} 미리보기 영상`,
                description,
                contentUrl: previewUrl,
                thumbnailUrl: displayThumbnailUrl,
                uploadDate: courseStartDate,
              },
            }),
            ...(hasValidReviews && {
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: reviewPoint,
                ratingCount: reviewCount,
                reviewCount,
              },
            }),
            category: {
              '@type': 'Thing',
              name: currentCategory?.cateName,
              url: `https://weolbu.com/class?cateSeq=${currentCategory.cateSeq}`,
            },
            ...(mergedReviewData.length > 0 && {
              review: mergedReviewData.map((review) => {
                return {
                  '@type': 'Review',
                  author: {
                    '@type': 'Person',
                    name: review?.nickName || review?.userName,
                  },
                  reviewBody: stripHtml(review?.reviewText || '').result,
                  datePublished: review?.regDate,
                  reviewRating: {
                    '@type': 'Rating',
                    bestRating: 5,
                    worstRating: 1,
                    ratingValue: Number(review?.reviewPoint),
                  },
                };
              }),
            }),
          }),
        },
      ],
    });
  };

  return {
    setProdcutDetail,
    setBreadcrumbList,
    setStructuredItemList,
    setSeoMeta,
    setCommunitySeoMeta,
  };
}

function removeEmojis(str) {
  if (!str) {
    return '';
  }
  const emojiRE = /(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;
  return str.replace(emojiRE, '');
}
