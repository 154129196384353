import { type ComputedRef, type WritableComputedRef } from 'vue';

import ASYNC_DATA_KEYS from '~/constants/async-data-keys';

import { useInterestStore } from '~/services/users/interesttags/store';

import { userInterestTagsService } from '../services';
import type { UserInterestRequestParam } from '../types';

const useUserInterestTagInfo = (isLoggedIn: ComputedRef<boolean>) => {
  const saveUserInterest = async (params: UserInterestRequestParam) => {
    const result = await userInterestTagsService.saveUserIntereestTags(params);
    return result;
  };

  const updateUserInterest = async (params: UserInterestRequestParam) => {
    const result = await userInterestTagsService.updateUserIntereestTags(params);
    return result;
  };

  const {
    data: interests,
    pending: interestsPending,
    refresh: refreshUserInterests,
  } = useAsyncDataWrapper(
    ASYNC_DATA_KEYS['/v1/users/interest-tags'](),
    async () => {
      const interestStore = useInterestStore();

      // 관심사 태그 전체 정보 호출
      const interestsMeta = await interestStore.fetchInterestMeta();

      let userInterestInfo = null;

      // 로그인 중이면 유저가 선택한 관심사 태그 정보 호출

      userInterestInfo = await interestStore.fetchMyInterest();

      if (!userInterestInfo) {
        return interestsMeta || [];
      }

      //유저가 이미 관심사 태그를 고른 경우 해당 태그를 선택하는 과정
      interestsMeta?.forEach((category) => {
        if (category.id === userInterestInfo?.category?.id) {
          category.select = true;
          category.tags.forEach((tag) => {
            if (userInterestInfo?.category?.tags?.find((userTag) => userTag.id === tag.id)) {
              tag.select = true;
            } else {
              tag.select = false;
            }
          });
        } else {
          category.select = false;
        }
      });

      return interestsMeta || [];
    },
    { server: false, immediate: true, watch: [isLoggedIn] },
  );

  return {
    interests,
    interestsPending,
    refreshUserInterests,
    saveUserInterest,
    updateUserInterest,
  };
};

export default useUserInterestTagInfo;
