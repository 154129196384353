<script setup lang="ts">
import { useMobile } from '~/composables/useMobile';

import Tracker from '~/libs/Tracker';

import Utility from '~/services/Utility';
import { injectPWAPromptEventContext } from '~/services/pwaV2';

import { isIOS } from '~/utils/browserUtil';

const { isInstallable } = injectPWAPromptEventContext();

const HomeOutlined = resolveComponent('HomeOutlined');
const HomeSolid = resolveComponent('HomeSolid');
const CommunityOutlined = resolveComponent('CommunityOutlined');
const CommunitySolid = resolveComponent('CommunitySolid');
const LectureOutlined = resolveComponent('LectureOutlined');
const LectureSolid = resolveComponent('LectureSolid');
const MypageOutlined = resolveComponent('MypageOutlined');
const MypageSolid = resolveComponent('MypageSolid');

const emit = defineEmits<{
  (eventName: 'active-nav', active: boolean): void;
}>();

const { isDesktop } = useMobile();

const route = useRoute();
const router = useRouter();
const isActive = ref(false);
// 클라이언트 이어야 하고, 모바일이어야 하며 액티브여야 한다.
// isDesktop 가 서버에서 랜더링 될때 값이 false 여서 초기 랜더링시 풋터가 보인다.
// 그래서 클라이언트에서만 로딩되게 처리
const isActiveBottomNav = computed(() => $isClient() && !isDesktop.value && isActive.value);

const navArr = computed(() => [
  {
    icon: ['HomeOutlined', 'HomeSolid'],
    title: '홈',
    url: '/',
    activePath: ['/'],
  },
  {
    icon: ['CommunityOutlined', 'CommunitySolid'],
    title: '커뮤니티',
    url: '/community',
    activePath: ['/community'],
  },
  {
    icon: ['LectureOutlined', 'LectureSolid'],
    title: '내강의',
    url: '/mypage/studyroom',
    activePath: ['/mypage/studyroom'],
    islogin: true,
  },
  {
    icon: ['MypageOutlined', 'MypageSolid'],
    title: '마이페이지',
    url: '/mypage',
    activePath: ['/mypage'],
    islogin: true,
  },
]);

const isEmpty = $isEmpty;
// 바텀 네비가 사라져야 하는 경로를 체크 한다. (바텀 네비가 보이지 않아야 하는 페이지)
watch(
  () => [route.path, route.query],
  ([path, query]) => {
    // 바텀 네비게이션을 보이지 않게 할 페이지
    // 클래스 상페, 결제, 커뮤니티 글쓰기, 아이디 찾기, 비밀번호 찾기, 이메일 회원가입
    if (
      ['/product', '/payment', '/community/form', '/find', '/find/pw', '/signup', '/notifications', '/pwa'].includes(
        path as string,
      )
    ) {
      isActive.value = false;
      return;
    }

    if (!isEmpty(path) && path.match(/\/product\/(\d+)/)) {
      isActive.value = false;
      return;
    }

    // 커뮤니티 상세 페이지
    if (!isEmpty(path) && path.match(/\/community\/(\d+)/)) {
      isActive.value = false;
      return;
    }

    // 커뮤니티 상세 페이지
    if (!isEmpty(path) && path.match(/\/wbtest\/(\d+)/)) {
      isActive.value = false;
      return;
    }

    // 이벤트 모든 상세 페이지
    if (!isEmpty(path) && path.match(/^\/class\/event\/.+$/)) {
      isActive.value = false;
      return;
    }

    isActive.value = true;
  },
  { immediate: true },
);

watch(
  () => isActiveBottomNav.value,
  (activeBottomNavVal) => {
    emit('active-nav', activeBottomNavVal);
  },
  { immediate: true },
);
// 아래 함수는 애니메이션을 위한 함수 로 사용하지 않으나 추후 사용될 수 있어서 형태만 남김
const onEnter = (el, done) => {
  // console.log('onEnter', el);
  // done();
};

const onLeave = (el, done) => {
  // console.log('onLeave', el);
  // done();
};

const onAfterLeave = (el) => {
  // console.log('onAfterLeave', el);
  // done();
};
</script>
<template>
  <Transition name="bottom-nav" @enter="onEnter" @leave="onLeave" @after-leave="onAfterLeave">
    <div v-if="isActiveBottomNav" :class="['wb-bottom-nav', isIOS() && !isInstallable ? 'ios' : '']">
      <div class="wb-bottom-nav-grid">
        <div
          v-for="navItem in navArr"
          :key="navItem.title"
          class="wb-bottom-nav-grid-item"
          :class="{ active: navItem.activePath.includes($route.path) }"
          @click="
            async () => {
              if ($route.path === navItem.url) return;
              // 로그인 처리
              if (navItem.islogin && !(await Utility.checkLoginThenRedirect())) {
                return;
              }

              Tracker['Click Navigation']({
                menuType: 'bottom',
                pageName: navItem.title,
                url: navItem.url,
              });
              router.push(navItem.url);
            }
          ">
          <HomeOutlined v-show="navItem.icon[navItem.activePath.includes($route.path) ? 1 : 0] === 'HomeOutlined'" />
          <HomeSolid v-show="navItem.icon[navItem.activePath.includes($route.path) ? 1 : 0] === 'HomeSolid'" />
          <CommunityOutlined
            v-show="navItem.icon[navItem.activePath.includes($route.path) ? 1 : 0] === 'CommunityOutlined'" />
          <CommunitySolid
            v-show="navItem.icon[navItem.activePath.includes($route.path) ? 1 : 0] === 'CommunitySolid'" />

          <LectureOutlined
            v-show="navItem.icon[navItem.activePath.includes($route.path) ? 1 : 0] === 'LectureOutlined'" />
          <LectureSolid v-show="navItem.icon[navItem.activePath.includes($route.path) ? 1 : 0] === 'LectureSolid'" />

          <MypageOutlined
            v-show="navItem.icon[navItem.activePath.includes($route.path) ? 1 : 0] === 'MypageOutlined'" />
          <MypageSolid v-show="navItem.icon[navItem.activePath.includes($route.path) ? 1 : 0] === 'MypageSolid'" />
          <div v-show="navItem.title !== '직장인재테크'">{{ navItem.title }}</div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
:root .wb-bottom-nav {
  --wb-bottom-nav-padding-bottom: 0;
  --wb-bottom-nav-height: 64px;
  --wb-bottom-nav-animate-translate-y: calc(14px + var(--wb-bottom-nav-height));
  --wb-bottom-nav-grid-cell-count: 4;
}
:root .wb-bottom-nav.ios {
  --wb-bottom-nav-padding-bottom: 12px;
  --wb-bottom-nav-height: calc(64px + var(--wb-bottom-nav-padding-bottom));
  --wb-bottom-nav-animate-translate-y: calc(14px + var(--wb-bottom-nav-height));
}
.wb-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--wb-bottom-nav-height);
  padding: 0 8px;
  padding-bottom: var(--wb-bottom-nav-padding-bottom);
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 1);
  z-index: 1000;
  .wb-bottom-nav-grid {
    display: grid;
    grid-template-columns: repeat(var(--wb-bottom-nav-grid-cell-count), 1fr);
    height: 100%;
    position: relative;
    .wb-bottom-nav-grid-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      color: #48546e;
      font-size: 10px;
      font-weight: 500;
      cursor: pointer;
      &.active {
        color: #091227;
        font-weight: 800;
      }
    }
  }
}

.bottom-nav-enter-active,
.bottom-nav-leave-active {
  transition: all 0.4s ease-out;
}

.bottom-nav-enter-from,
.bottom-nav-leave-to {
  transform: translate3d(0, var(--wb-bottom-nav-animate-translate-y), 0);
}

.bottom-nav-enter-to,
.bottom-nav-leave-from {
  transform: translate3d(0, 0, 0);
}
</style>
