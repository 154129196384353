<script setup lang="ts">
import { withDefaults } from 'vue';
import { type InterestTag } from '~~/src/services/common';

import { useBasicsStore } from '~/services/base';

import { StorageItem } from '~/shared/StorageItem';
import { REDIRECT_URI } from '~/shared/auth/client';

const basicStore = useBasicsStore();
const router = useRouter();

const props = withDefaults(
  defineProps<{
    mainInterest: { id: number; name: string; description: string };
    detailInterests: InterestTag[];
    isLoggedIn: boolean;
  }>(),
  {},
);

const emit = defineEmits<{
  (eventName: 'update:toggleDetailInterest', detailInterestId: number): void;
  (eventName: 'update:saveTempSelectedTagInfo'): void;
}>();

const showLoginModal = () => {
  emit('update:saveTempSelectedTagInfo');
  StorageItem.local(REDIRECT_URI).set(router.currentRoute.value.fullPath);

  // 로그인 모달 띄우기
  basicStore.setLoginModalState(true);
};
</script>
<template>
  <div class="text-base mb-6 mt-11">
    <div class="space-y-5 md:space-y-2">
      <div class="flex flex-col gap-2">
        <label class="p-4 border rounded cursor-pointer border-primary-600">
          <div class="flex items-center">
            <div class="w-5 h-5 rounded-full border-primary-600 border-4"></div>
            <span class="pl-2 font-semibold text-neutral-1000 leading-normal">{{ mainInterest.name }}</span>
          </div>

          <div class="text-neutral-500 text-sm font-normal leading-tight pl-7">
            {{ mainInterest?.description || '' }}
          </div>
        </label>
      </div>
    </div>
    <div class="mt-6">
      <div class="text-base font-bold leading-normal text-neutral-1000">
        {{ `세부 주제를 2개 이상 선택해 주세요` }}
      </div>

      <div class="flex items-start justify-start flex-wrap mt-2 gap-2">
        <div v-for="detail in detailInterests.filter((value) => !value.isDefault)" :key="detail.id">
          <SelectChip
            :label="detail.name"
            :is-selected="detail.select"
            @toggle="emit('update:toggleDetailInterest', detail.id)" />
        </div>
      </div>
    </div>
    <p
      v-if="!isLoggedIn"
      class="font-normal px-4 py-2 bg-primary-100 mt-6 rounded text-center text-neutral-1000 cursor-pointer break-keep"
      @click="showLoginModal">
      💁‍♀️ 계정에 관심사 저장하고 맞춤 추천받으세요!
      <span class="text-primary-600 font-bold underline"> 로그인</span>
    </p>
  </div>
</template>
