import { default as benepiaI48bqIKZSJMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/authorization/benepia.vue?macro=true";
import { default as authorizeO9fdO0qrWxMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/authorize.vue?macro=true";
import { default as _91id_93RSMfz04toMMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/[id].vue?macro=true";
import { default as _2024weolbuconqOMAQCleOfMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/2024weolbucon.vue?macro=true";
import { default as _3step_45investvL81rrJdCyMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/3step-invest.vue?macro=true";
import { default as chuseok20245TNMjxNqCSMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/chuseok2024.vue?macro=true";
import { default as discountXrlVvcUVvzMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/discount.vue?macro=true";
import { default as indexRGbDC3EKRjMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/index.vue?macro=true";
import { default as introduce_45friendX2b63VH2aaMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/introduce-friend.vue?macro=true";
import { default as invest_45roadmapnG5X92B63aMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/invest-roadmap.vue?macro=true";
import { default as lunar_45new_45year_45promotion_45teaserxJHbjarQZiMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/lunar-new-year-promotion-teaser.vue?macro=true";
import { default as lunar_45new_45year_45promotionfS2kuK2NVOMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/lunar-new-year-promotion.vue?macro=true";
import { default as summer_45discountJIgUZDPxGKMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/summer-discount.vue?macro=true";
import { default as with_45hj79QqTJEUMxMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/with-hj.vue?macro=true";
import { default as with_45nnweeyCCztGgZEDMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/with-nnwee.vue?macro=true";
import { default as indexmfFF0DBCAcMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/index.vue?macro=true";
import { default as _91id_93ocTn7aGYtHMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/community/[id].vue?macro=true";
import { default as _91_91id_93_93rfOpp6toiKMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/community/form/[[id]].vue?macro=true";
import { default as indexNgAot9EZ6PMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/community/index.vue?macro=true";
import { default as studygroupnmT6u7h2kiMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/community/studygroup.vue?macro=true";
import { default as curriculumK6A3piPldQMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/curriculum.vue?macro=true";
import { default as change_45pwDu5U0HXs4rMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/change-pw.vue?macro=true";
import { default as check_45idz5mKWrUcTLMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/check-id.vue?macro=true";
import { default as indexKuNzRCITD0Meta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/index/index.vue?macro=true";
import { default as pwAK9423efb8Meta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/index/pw.vue?macro=true";
import { default as indexsragimYlCBMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/index.vue?macro=true";
import { default as copyrightLC8Jr6CXKdMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/copyright.vue?macro=true";
import { default as indexsEK87AmjJgMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/faq/index.vue?macro=true";
import { default as _91noticeSeq_93OOGZfD71aOMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/notice/[noticeSeq].vue?macro=true";
import { default as indexhhaFGud9l6Meta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/notice/index.vue?macro=true";
import { default as privacyRPEGY9pk03Meta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/privacy.vue?macro=true";
import { default as terms7tieyYEeJbMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/terms.vue?macro=true";
import { default as indexq6EvLgyF4EMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index.vue?macro=true";
import { default as marketingFfjsrcLbieMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/marketing.vue?macro=true";
import { default as indexWOZ5QQQmHZMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/index.vue?macro=true";
import { default as marketingakQTiQKbY5Meta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/marketing.vue?macro=true";
import { default as migration8Pl8ae0jBiMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/migration.vue?macro=true";
import { default as couponWIZSzvvMuOMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/coupon.vue?macro=true";
import { default as faqjBNwch3J0fMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/faq.vue?macro=true";
import { default as favoriteybgjpdu78JMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/favorite.vue?macro=true";
import { default as honors5Jt1zoXrW6Meta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/honors.vue?macro=true";
import { default as indexPK8By52B0VMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/index.vue?macro=true";
import { default as infoPaZDFWUFYPMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/info.vue?macro=true";
import { default as leavecylLebuVaiMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/leave.vue?macro=true";
import { default as paymentqz08FGDDzsMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/payment.vue?macro=true";
import { default as pointcMZyp56ewIMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/point.vue?macro=true";
import { default as reviewyffs4g1vneMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/review.vue?macro=true";
import { default as indexd1FsHc1ZVtMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/studyroom/index.vue?macro=true";
import { default as lectureF0GB5zeLDbMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/studyroom/lecture.vue?macro=true";
import { default as reportydd0nJzsnIMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/studyroom/report.vue?macro=true";
import { default as indexzlp62KLEPtMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index.vue?macro=true";
import { default as edit4uarFkLJdPMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/report/edit.vue?macro=true";
import { default as notificationsXNGk7TNxdVMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/notifications.vue?macro=true";
import { default as indexfQQeAV8EoCMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/payment/index.vue?macro=true";
import { default as _91id_93IqlwlRydmjMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/product/[id].vue?macro=true";
import { default as indexZXvuB2hixrMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/product/index.vue?macro=true";
import { default as badgexhOQdi0zdPMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/profile/[id]/badge.vue?macro=true";
import { default as followPse5IrAZqlMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/profile/[id]/follow.vue?macro=true";
import { default as indexPEJ7s6lTuBMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/profile/[id]/index.vue?macro=true";
import { default as _91id_93aHGRKOJBGCMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/profile/[id].vue?macro=true";
import { default as indexHsb6y3BUmuMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/pwa/index.vue?macro=true";
import { default as _91keyword_93KZ8cbchbSrMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/search/[keyword].vue?macro=true";
import { default as signup1kvySWlI0rMeta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/signup.vue?macro=true";
import { default as component_45stubfh4Fon00a0Meta } from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubfh4Fon00a0 } from "/home/runner/work/weolbu-frontend/weolbu-frontend/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: benepiaI48bqIKZSJMeta?.name ?? "authorization-benepia",
    path: benepiaI48bqIKZSJMeta?.path ?? "/authorization/benepia",
    meta: benepiaI48bqIKZSJMeta || {},
    alias: benepiaI48bqIKZSJMeta?.alias || [],
    redirect: benepiaI48bqIKZSJMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/authorization/benepia.vue").then(m => m.default || m)
  },
  {
    name: authorizeO9fdO0qrWxMeta?.name ?? "authorize",
    path: authorizeO9fdO0qrWxMeta?.path ?? "/authorize",
    meta: authorizeO9fdO0qrWxMeta || {},
    alias: authorizeO9fdO0qrWxMeta?.alias || [],
    redirect: authorizeO9fdO0qrWxMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RSMfz04toMMeta?.name ?? "class-event-id",
    path: _91id_93RSMfz04toMMeta?.path ?? "/class/event/:id()",
    meta: _91id_93RSMfz04toMMeta || {},
    alias: _91id_93RSMfz04toMMeta?.alias || [],
    redirect: _91id_93RSMfz04toMMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/[id].vue").then(m => m.default || m)
  },
  {
    name: _2024weolbuconqOMAQCleOfMeta?.name ?? "class-event-2024weolbucon",
    path: _2024weolbuconqOMAQCleOfMeta?.path ?? "/class/event/2024weolbucon",
    meta: _2024weolbuconqOMAQCleOfMeta || {},
    alias: _2024weolbuconqOMAQCleOfMeta?.alias || [],
    redirect: _2024weolbuconqOMAQCleOfMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/2024weolbucon.vue").then(m => m.default || m)
  },
  {
    name: _3step_45investvL81rrJdCyMeta?.name ?? "class-event-3step-invest",
    path: _3step_45investvL81rrJdCyMeta?.path ?? "/class/event/3step-invest",
    meta: _3step_45investvL81rrJdCyMeta || {},
    alias: _3step_45investvL81rrJdCyMeta?.alias || [],
    redirect: _3step_45investvL81rrJdCyMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/3step-invest.vue").then(m => m.default || m)
  },
  {
    name: chuseok20245TNMjxNqCSMeta?.name ?? "class-event-chuseok2024",
    path: chuseok20245TNMjxNqCSMeta?.path ?? "/class/event/chuseok2024",
    meta: chuseok20245TNMjxNqCSMeta || {},
    alias: chuseok20245TNMjxNqCSMeta?.alias || [],
    redirect: chuseok20245TNMjxNqCSMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/chuseok2024.vue").then(m => m.default || m)
  },
  {
    name: discountXrlVvcUVvzMeta?.name ?? "class-event-discount",
    path: discountXrlVvcUVvzMeta?.path ?? "/class/event/discount",
    meta: discountXrlVvcUVvzMeta || {},
    alias: discountXrlVvcUVvzMeta?.alias || [],
    redirect: discountXrlVvcUVvzMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/discount.vue").then(m => m.default || m)
  },
  {
    name: indexRGbDC3EKRjMeta?.name ?? "class-event",
    path: indexRGbDC3EKRjMeta?.path ?? "/class/event",
    meta: indexRGbDC3EKRjMeta || {},
    alias: indexRGbDC3EKRjMeta?.alias || [],
    redirect: indexRGbDC3EKRjMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/index.vue").then(m => m.default || m)
  },
  {
    name: introduce_45friendX2b63VH2aaMeta?.name ?? "class-event-introduce-friend",
    path: introduce_45friendX2b63VH2aaMeta?.path ?? "/class/event/introduce-friend",
    meta: introduce_45friendX2b63VH2aaMeta || {},
    alias: introduce_45friendX2b63VH2aaMeta?.alias || [],
    redirect: introduce_45friendX2b63VH2aaMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/introduce-friend.vue").then(m => m.default || m)
  },
  {
    name: invest_45roadmapnG5X92B63aMeta?.name ?? "class-event-invest-roadmap",
    path: invest_45roadmapnG5X92B63aMeta?.path ?? "/class/event/invest-roadmap",
    meta: invest_45roadmapnG5X92B63aMeta || {},
    alias: invest_45roadmapnG5X92B63aMeta?.alias || [],
    redirect: invest_45roadmapnG5X92B63aMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/invest-roadmap.vue").then(m => m.default || m)
  },
  {
    name: lunar_45new_45year_45promotion_45teaserxJHbjarQZiMeta?.name ?? "class-event-lunar-new-year-promotion-teaser",
    path: lunar_45new_45year_45promotion_45teaserxJHbjarQZiMeta?.path ?? "/class/event/lunar-new-year-promotion-teaser",
    meta: lunar_45new_45year_45promotion_45teaserxJHbjarQZiMeta || {},
    alias: lunar_45new_45year_45promotion_45teaserxJHbjarQZiMeta?.alias || [],
    redirect: lunar_45new_45year_45promotion_45teaserxJHbjarQZiMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/lunar-new-year-promotion-teaser.vue").then(m => m.default || m)
  },
  {
    name: lunar_45new_45year_45promotionfS2kuK2NVOMeta?.name ?? "class-event-lunar-new-year-promotion",
    path: lunar_45new_45year_45promotionfS2kuK2NVOMeta?.path ?? "/class/event/lunar-new-year-promotion",
    meta: lunar_45new_45year_45promotionfS2kuK2NVOMeta || {},
    alias: lunar_45new_45year_45promotionfS2kuK2NVOMeta?.alias || [],
    redirect: lunar_45new_45year_45promotionfS2kuK2NVOMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/lunar-new-year-promotion.vue").then(m => m.default || m)
  },
  {
    name: summer_45discountJIgUZDPxGKMeta?.name ?? "class-event-summer-discount",
    path: summer_45discountJIgUZDPxGKMeta?.path ?? "/class/event/summer-discount",
    meta: summer_45discountJIgUZDPxGKMeta || {},
    alias: summer_45discountJIgUZDPxGKMeta?.alias || [],
    redirect: summer_45discountJIgUZDPxGKMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/summer-discount.vue").then(m => m.default || m)
  },
  {
    name: with_45hj79QqTJEUMxMeta?.name ?? "class-event-with-hj",
    path: with_45hj79QqTJEUMxMeta?.path ?? "/class/event/with-hj",
    meta: with_45hj79QqTJEUMxMeta || {},
    alias: with_45hj79QqTJEUMxMeta?.alias || [],
    redirect: with_45hj79QqTJEUMxMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/with-hj.vue").then(m => m.default || m)
  },
  {
    name: with_45nnweeyCCztGgZEDMeta?.name ?? "class-event-with-nnwee",
    path: with_45nnweeyCCztGgZEDMeta?.path ?? "/class/event/with-nnwee",
    meta: with_45nnweeyCCztGgZEDMeta || {},
    alias: with_45nnweeyCCztGgZEDMeta?.alias || [],
    redirect: with_45nnweeyCCztGgZEDMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/event/with-nnwee.vue").then(m => m.default || m)
  },
  {
    name: indexmfFF0DBCAcMeta?.name ?? "class",
    path: indexmfFF0DBCAcMeta?.path ?? "/class",
    meta: indexmfFF0DBCAcMeta || {},
    alias: indexmfFF0DBCAcMeta?.alias || [],
    redirect: indexmfFF0DBCAcMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/class/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ocTn7aGYtHMeta?.name ?? "community-id",
    path: _91id_93ocTn7aGYtHMeta?.path ?? "/community/:id()",
    meta: _91id_93ocTn7aGYtHMeta || {},
    alias: _91id_93ocTn7aGYtHMeta?.alias || [],
    redirect: _91id_93ocTn7aGYtHMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/community/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93rfOpp6toiKMeta?.name ?? "community-form-id",
    path: _91_91id_93_93rfOpp6toiKMeta?.path ?? "/community/form/:id?",
    meta: _91_91id_93_93rfOpp6toiKMeta || {},
    alias: _91_91id_93_93rfOpp6toiKMeta?.alias || [],
    redirect: _91_91id_93_93rfOpp6toiKMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/community/form/[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexNgAot9EZ6PMeta?.name ?? "community",
    path: indexNgAot9EZ6PMeta?.path ?? "/community",
    meta: indexNgAot9EZ6PMeta || {},
    alias: indexNgAot9EZ6PMeta?.alias || [],
    redirect: indexNgAot9EZ6PMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/community/index.vue").then(m => m.default || m)
  },
  {
    name: studygroupnmT6u7h2kiMeta?.name ?? "community-studygroup",
    path: studygroupnmT6u7h2kiMeta?.path ?? "/community/studygroup",
    meta: studygroupnmT6u7h2kiMeta || {},
    alias: studygroupnmT6u7h2kiMeta?.alias || [],
    redirect: studygroupnmT6u7h2kiMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/community/studygroup.vue").then(m => m.default || m)
  },
  {
    name: curriculumK6A3piPldQMeta?.name ?? "curriculum",
    path: curriculumK6A3piPldQMeta?.path ?? "/curriculum",
    meta: curriculumK6A3piPldQMeta || {},
    alias: curriculumK6A3piPldQMeta?.alias || [],
    redirect: curriculumK6A3piPldQMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/curriculum.vue").then(m => m.default || m)
  },
  {
    name: change_45pwDu5U0HXs4rMeta?.name ?? "find-change-pw",
    path: change_45pwDu5U0HXs4rMeta?.path ?? "/find/change-pw",
    meta: change_45pwDu5U0HXs4rMeta || {},
    alias: change_45pwDu5U0HXs4rMeta?.alias || [],
    redirect: change_45pwDu5U0HXs4rMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/change-pw.vue").then(m => m.default || m)
  },
  {
    name: check_45idz5mKWrUcTLMeta?.name ?? "find-check-id",
    path: check_45idz5mKWrUcTLMeta?.path ?? "/find/check-id",
    meta: check_45idz5mKWrUcTLMeta || {},
    alias: check_45idz5mKWrUcTLMeta?.alias || [],
    redirect: check_45idz5mKWrUcTLMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/check-id.vue").then(m => m.default || m)
  },
  {
    name: indexsragimYlCBMeta?.name ?? undefined,
    path: indexsragimYlCBMeta?.path ?? "/find",
    meta: indexsragimYlCBMeta || {},
    alias: indexsragimYlCBMeta?.alias || [],
    redirect: indexsragimYlCBMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/index.vue").then(m => m.default || m),
    children: [
  {
    name: indexKuNzRCITD0Meta?.name ?? "find-index",
    path: indexKuNzRCITD0Meta?.path ?? "",
    meta: indexKuNzRCITD0Meta || {},
    alias: indexKuNzRCITD0Meta?.alias || [],
    redirect: indexKuNzRCITD0Meta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/index/index.vue").then(m => m.default || m)
  },
  {
    name: pwAK9423efb8Meta?.name ?? "find-index-pw",
    path: pwAK9423efb8Meta?.path ?? "pw",
    meta: pwAK9423efb8Meta || {},
    alias: pwAK9423efb8Meta?.alias || [],
    redirect: pwAK9423efb8Meta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/find/index/pw.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexq6EvLgyF4EMeta?.name ?? "footer",
    path: indexq6EvLgyF4EMeta?.path ?? "/footer",
    meta: indexq6EvLgyF4EMeta || {},
    alias: indexq6EvLgyF4EMeta?.alias || [],
    redirect: indexq6EvLgyF4EMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index.vue").then(m => m.default || m),
    children: [
  {
    name: copyrightLC8Jr6CXKdMeta?.name ?? "footer-index-copyright",
    path: copyrightLC8Jr6CXKdMeta?.path ?? "copyright",
    meta: copyrightLC8Jr6CXKdMeta || {},
    alias: copyrightLC8Jr6CXKdMeta?.alias || [],
    redirect: copyrightLC8Jr6CXKdMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/copyright.vue").then(m => m.default || m)
  },
  {
    name: indexsEK87AmjJgMeta?.name ?? "footer-index-faq",
    path: indexsEK87AmjJgMeta?.path ?? "faq",
    meta: indexsEK87AmjJgMeta || {},
    alias: indexsEK87AmjJgMeta?.alias || [],
    redirect: indexsEK87AmjJgMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/faq/index.vue").then(m => m.default || m)
  },
  {
    name: _91noticeSeq_93OOGZfD71aOMeta?.name ?? "footer-index-notice-noticeSeq",
    path: _91noticeSeq_93OOGZfD71aOMeta?.path ?? "notice/:noticeSeq()",
    meta: _91noticeSeq_93OOGZfD71aOMeta || {},
    alias: _91noticeSeq_93OOGZfD71aOMeta?.alias || [],
    redirect: _91noticeSeq_93OOGZfD71aOMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/notice/[noticeSeq].vue").then(m => m.default || m)
  },
  {
    name: indexhhaFGud9l6Meta?.name ?? "footer-index-notice",
    path: indexhhaFGud9l6Meta?.path ?? "notice",
    meta: indexhhaFGud9l6Meta || {},
    alias: indexhhaFGud9l6Meta?.alias || [],
    redirect: indexhhaFGud9l6Meta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/notice/index.vue").then(m => m.default || m)
  },
  {
    name: privacyRPEGY9pk03Meta?.name ?? "footer-index-privacy",
    path: privacyRPEGY9pk03Meta?.path ?? "privacy",
    meta: privacyRPEGY9pk03Meta || {},
    alias: privacyRPEGY9pk03Meta?.alias || [],
    redirect: privacyRPEGY9pk03Meta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/privacy.vue").then(m => m.default || m)
  },
  {
    name: terms7tieyYEeJbMeta?.name ?? "footer-index-terms",
    path: terms7tieyYEeJbMeta?.path ?? "terms",
    meta: terms7tieyYEeJbMeta || {},
    alias: terms7tieyYEeJbMeta?.alias || [],
    redirect: terms7tieyYEeJbMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/index/terms.vue").then(m => m.default || m)
  }
]
  },
  {
    name: marketingFfjsrcLbieMeta?.name ?? "footer-marketing",
    path: marketingFfjsrcLbieMeta?.path ?? "/footer/marketing",
    meta: marketingFfjsrcLbieMeta || {},
    alias: marketingFfjsrcLbieMeta?.alias || [],
    redirect: marketingFfjsrcLbieMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/footer/marketing.vue").then(m => m.default || m)
  },
  {
    name: indexWOZ5QQQmHZMeta?.name ?? "index",
    path: indexWOZ5QQQmHZMeta?.path ?? "/",
    meta: indexWOZ5QQQmHZMeta || {},
    alias: indexWOZ5QQQmHZMeta?.alias || [],
    redirect: indexWOZ5QQQmHZMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: marketingakQTiQKbY5Meta?.name ?? "marketing",
    path: marketingakQTiQKbY5Meta?.path ?? "/marketing",
    meta: marketingakQTiQKbY5Meta || {},
    alias: marketingakQTiQKbY5Meta?.alias || [],
    redirect: marketingakQTiQKbY5Meta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/marketing.vue").then(m => m.default || m)
  },
  {
    name: migration8Pl8ae0jBiMeta?.name ?? "migration",
    path: migration8Pl8ae0jBiMeta?.path ?? "/migration",
    meta: migration8Pl8ae0jBiMeta || {},
    alias: migration8Pl8ae0jBiMeta?.alias || [],
    redirect: migration8Pl8ae0jBiMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/migration.vue").then(m => m.default || m)
  },
  {
    name: indexzlp62KLEPtMeta?.name ?? undefined,
    path: indexzlp62KLEPtMeta?.path ?? "/mypage",
    meta: indexzlp62KLEPtMeta || {},
    alias: indexzlp62KLEPtMeta?.alias || [],
    redirect: indexzlp62KLEPtMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index.vue").then(m => m.default || m),
    children: [
  {
    name: couponWIZSzvvMuOMeta?.name ?? "mypage-index-coupon",
    path: couponWIZSzvvMuOMeta?.path ?? "coupon",
    meta: couponWIZSzvvMuOMeta || {},
    alias: couponWIZSzvvMuOMeta?.alias || [],
    redirect: couponWIZSzvvMuOMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/coupon.vue").then(m => m.default || m)
  },
  {
    name: faqjBNwch3J0fMeta?.name ?? "mypage-index-faq",
    path: faqjBNwch3J0fMeta?.path ?? "faq",
    meta: faqjBNwch3J0fMeta || {},
    alias: faqjBNwch3J0fMeta?.alias || [],
    redirect: faqjBNwch3J0fMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/faq.vue").then(m => m.default || m)
  },
  {
    name: favoriteybgjpdu78JMeta?.name ?? "mypage-index-favorite",
    path: favoriteybgjpdu78JMeta?.path ?? "favorite",
    meta: favoriteybgjpdu78JMeta || {},
    alias: favoriteybgjpdu78JMeta?.alias || [],
    redirect: favoriteybgjpdu78JMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/favorite.vue").then(m => m.default || m)
  },
  {
    name: honors5Jt1zoXrW6Meta?.name ?? "mypage-index-honors",
    path: honors5Jt1zoXrW6Meta?.path ?? "honors",
    meta: honors5Jt1zoXrW6Meta || {},
    alias: honors5Jt1zoXrW6Meta?.alias || [],
    redirect: honors5Jt1zoXrW6Meta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/honors.vue").then(m => m.default || m)
  },
  {
    name: indexPK8By52B0VMeta?.name ?? "mypage-index",
    path: indexPK8By52B0VMeta?.path ?? "",
    meta: indexPK8By52B0VMeta || {},
    alias: indexPK8By52B0VMeta?.alias || [],
    redirect: indexPK8By52B0VMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/index.vue").then(m => m.default || m)
  },
  {
    name: infoPaZDFWUFYPMeta?.name ?? "mypage-index-info",
    path: infoPaZDFWUFYPMeta?.path ?? "info",
    meta: infoPaZDFWUFYPMeta || {},
    alias: infoPaZDFWUFYPMeta?.alias || [],
    redirect: infoPaZDFWUFYPMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/info.vue").then(m => m.default || m)
  },
  {
    name: leavecylLebuVaiMeta?.name ?? "mypage-index-leave",
    path: leavecylLebuVaiMeta?.path ?? "leave",
    meta: leavecylLebuVaiMeta || {},
    alias: leavecylLebuVaiMeta?.alias || [],
    redirect: leavecylLebuVaiMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/leave.vue").then(m => m.default || m)
  },
  {
    name: paymentqz08FGDDzsMeta?.name ?? "mypage-index-payment",
    path: paymentqz08FGDDzsMeta?.path ?? "payment",
    meta: paymentqz08FGDDzsMeta || {},
    alias: paymentqz08FGDDzsMeta?.alias || [],
    redirect: paymentqz08FGDDzsMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/payment.vue").then(m => m.default || m)
  },
  {
    name: pointcMZyp56ewIMeta?.name ?? "mypage-index-point",
    path: pointcMZyp56ewIMeta?.path ?? "point",
    meta: pointcMZyp56ewIMeta || {},
    alias: pointcMZyp56ewIMeta?.alias || [],
    redirect: pointcMZyp56ewIMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/point.vue").then(m => m.default || m)
  },
  {
    name: reviewyffs4g1vneMeta?.name ?? "mypage-index-review",
    path: reviewyffs4g1vneMeta?.path ?? "review",
    meta: reviewyffs4g1vneMeta || {},
    alias: reviewyffs4g1vneMeta?.alias || [],
    redirect: reviewyffs4g1vneMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/review.vue").then(m => m.default || m)
  },
  {
    name: indexd1FsHc1ZVtMeta?.name ?? "mypage-index-studyroom",
    path: indexd1FsHc1ZVtMeta?.path ?? "studyroom",
    meta: indexd1FsHc1ZVtMeta || {},
    alias: indexd1FsHc1ZVtMeta?.alias || [],
    redirect: indexd1FsHc1ZVtMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/studyroom/index.vue").then(m => m.default || m)
  },
  {
    name: lectureF0GB5zeLDbMeta?.name ?? "mypage-index-studyroom-lecture",
    path: lectureF0GB5zeLDbMeta?.path ?? "studyroom/lecture",
    meta: lectureF0GB5zeLDbMeta || {},
    alias: lectureF0GB5zeLDbMeta?.alias || [],
    redirect: lectureF0GB5zeLDbMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/studyroom/lecture.vue").then(m => m.default || m)
  },
  {
    name: reportydd0nJzsnIMeta?.name ?? "mypage-index-studyroom-report",
    path: reportydd0nJzsnIMeta?.path ?? "studyroom/report",
    meta: reportydd0nJzsnIMeta || {},
    alias: reportydd0nJzsnIMeta?.alias || [],
    redirect: reportydd0nJzsnIMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/index/studyroom/report.vue").then(m => m.default || m)
  }
]
  },
  {
    name: edit4uarFkLJdPMeta?.name ?? "mypage-report-edit",
    path: edit4uarFkLJdPMeta?.path ?? "/mypage/report/edit",
    meta: edit4uarFkLJdPMeta || {},
    alias: edit4uarFkLJdPMeta?.alias || [],
    redirect: edit4uarFkLJdPMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/mypage/report/edit.vue").then(m => m.default || m)
  },
  {
    name: notificationsXNGk7TNxdVMeta?.name ?? "notifications",
    path: notificationsXNGk7TNxdVMeta?.path ?? "/notifications",
    meta: notificationsXNGk7TNxdVMeta || {},
    alias: notificationsXNGk7TNxdVMeta?.alias || [],
    redirect: notificationsXNGk7TNxdVMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: indexfQQeAV8EoCMeta?.name ?? "payment",
    path: indexfQQeAV8EoCMeta?.path ?? "/payment",
    meta: indexfQQeAV8EoCMeta || {},
    alias: indexfQQeAV8EoCMeta?.alias || [],
    redirect: indexfQQeAV8EoCMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93IqlwlRydmjMeta?.name ?? "product-id",
    path: _91id_93IqlwlRydmjMeta?.path ?? "/product/:id()",
    meta: _91id_93IqlwlRydmjMeta || {},
    alias: _91id_93IqlwlRydmjMeta?.alias || [],
    redirect: _91id_93IqlwlRydmjMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/product/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZXvuB2hixrMeta?.name ?? "product",
    path: indexZXvuB2hixrMeta?.path ?? "/product",
    meta: indexZXvuB2hixrMeta || {},
    alias: indexZXvuB2hixrMeta?.alias || [],
    redirect: indexZXvuB2hixrMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93aHGRKOJBGCMeta?.name ?? undefined,
    path: _91id_93aHGRKOJBGCMeta?.path ?? "/profile/:id()",
    meta: _91id_93aHGRKOJBGCMeta || {},
    alias: _91id_93aHGRKOJBGCMeta?.alias || [],
    redirect: _91id_93aHGRKOJBGCMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/profile/[id].vue").then(m => m.default || m),
    children: [
  {
    name: badgexhOQdi0zdPMeta?.name ?? "profile-id-badge",
    path: badgexhOQdi0zdPMeta?.path ?? "badge",
    meta: badgexhOQdi0zdPMeta || {},
    alias: badgexhOQdi0zdPMeta?.alias || [],
    redirect: badgexhOQdi0zdPMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/profile/[id]/badge.vue").then(m => m.default || m)
  },
  {
    name: followPse5IrAZqlMeta?.name ?? "profile-id-follow",
    path: followPse5IrAZqlMeta?.path ?? "follow",
    meta: followPse5IrAZqlMeta || {},
    alias: followPse5IrAZqlMeta?.alias || [],
    redirect: followPse5IrAZqlMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/profile/[id]/follow.vue").then(m => m.default || m)
  },
  {
    name: indexPEJ7s6lTuBMeta?.name ?? "profile-id",
    path: indexPEJ7s6lTuBMeta?.path ?? "",
    meta: indexPEJ7s6lTuBMeta || {},
    alias: indexPEJ7s6lTuBMeta?.alias || [],
    redirect: indexPEJ7s6lTuBMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/profile/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexHsb6y3BUmuMeta?.name ?? "pwa",
    path: indexHsb6y3BUmuMeta?.path ?? "/pwa",
    meta: indexHsb6y3BUmuMeta || {},
    alias: indexHsb6y3BUmuMeta?.alias || [],
    redirect: indexHsb6y3BUmuMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/pwa/index.vue").then(m => m.default || m)
  },
  {
    name: _91keyword_93KZ8cbchbSrMeta?.name ?? "search-keyword",
    path: _91keyword_93KZ8cbchbSrMeta?.path ?? "/search/:keyword()",
    meta: _91keyword_93KZ8cbchbSrMeta || {},
    alias: _91keyword_93KZ8cbchbSrMeta?.alias || [],
    redirect: _91keyword_93KZ8cbchbSrMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/search/[keyword].vue").then(m => m.default || m)
  },
  {
    name: signup1kvySWlI0rMeta?.name ?? "signup",
    path: signup1kvySWlI0rMeta?.path ?? "/signup",
    meta: signup1kvySWlI0rMeta || {},
    alias: signup1kvySWlI0rMeta?.alias || [],
    redirect: signup1kvySWlI0rMeta?.redirect,
    component: () => import("/home/runner/work/weolbu-frontend/weolbu-frontend/src/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: component_45stubfh4Fon00a0Meta?.name ?? undefined,
    path: component_45stubfh4Fon00a0Meta?.path ?? "/gnu/:pathMatch(.*)",
    meta: component_45stubfh4Fon00a0Meta || {},
    alias: component_45stubfh4Fon00a0Meta?.alias || [],
    redirect: component_45stubfh4Fon00a0Meta?.redirect,
    component: component_45stubfh4Fon00a0
  },
  {
    name: component_45stubfh4Fon00a0Meta?.name ?? undefined,
    path: component_45stubfh4Fon00a0Meta?.path ?? "/lecture/:pathMatch(.*)",
    meta: component_45stubfh4Fon00a0Meta || {},
    alias: component_45stubfh4Fon00a0Meta?.alias || [],
    redirect: component_45stubfh4Fon00a0Meta?.redirect,
    component: component_45stubfh4Fon00a0
  },
  {
    name: component_45stubfh4Fon00a0Meta?.name ?? undefined,
    path: component_45stubfh4Fon00a0Meta?.path ?? "/login.pnp/:pathMatch(.*)",
    meta: component_45stubfh4Fon00a0Meta || {},
    alias: component_45stubfh4Fon00a0Meta?.alias || [],
    redirect: component_45stubfh4Fon00a0Meta?.redirect,
    component: component_45stubfh4Fon00a0
  }
]