<script setup lang="ts">
import { type InterestTagCategory } from '~/services/common';

const props = withDefaults(
  defineProps<{
    interests: InterestTagCategory[];
    interestSelect: number | null;
    marketing: boolean;
    showMarketingCheckbox: boolean;
    interestInfo: { [key: number]: { subTitle: string; redirectUrl: string } };
  }>(),
  {},
);

const emit = defineEmits<{
  (eventName: 'update:interesetSelect', interest: number): void;
  (eventName: 'update:marketing', marketing: boolean): void;
}>();
</script>
<template>
  <div class="text-base mb-6">
    <div class="mt-[48px] text-base font-bold leading-normal text-neutral-1000 mb-2 md:mt-[44px]">
      어떤 분야에 관심이 있으세요?
    </div>
    <div class="space-y-2">
      <div v-for="(item, index) in interests" :key="index" class="flex flex-col gap-2">
        <label
          :key="index"
          class="p-4 border rounded cursor-pointer border-neutral-alpha-300"
          :class="{ 'border-primary-600': interestSelect === item.id }">
          <div class="flex items-center">
            <RadioButton
              :model-value="interestSelect"
              :value="item.id"
              @change="emit('update:interesetSelect', item.id)" />
            <span class="pl-2 font-semibold text-neutral-1000 leading-normal">{{ item.name }}</span>
          </div>

          <div class="text-neutral-500 text-sm font-normal leading-tight pl-7 break-keep">
            {{ item?.description || '' }}
          </div>
        </label>
      </div>
      <div v-if="showMarketingCheckbox" class="flex flex-row gap-2 mb-10 py-4 items-center">
        <Checkbox
          :model-value="marketing"
          class="checkbox"
          input-id="marketing-yn"
          binary
          @input="(value) => emit('update:marketing', value)" />
        <label for="marketing-yn">
          <p class="text-neutral-800 text-sm">관심 주제에 딱 맞는 혜택과 새 강의 소식 받아볼게요!</p>
        </label>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.p-checkbox-checked {
  .p-checkbox-box.p-checkbox-box.p-checkbox-box {
    border-color: var(--primary-600);
    background-color: var(--primary-600);
  }
}
.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--neutral-300);
}

.p-radiobutton-checked {
  .p-radiobutton-box.p-radiobutton-box.p-radiobutton-box {
    border-color: var(--primary-600);
    border-width: 4px;
  }
}

.p-radiobutton {
  width: 20px;
  height: 20px;
  .p-radiobutton-box.p-radiobutton-box.p-radiobutton-box {
    width: 100%;
    height: 100%;
  }
  .p-radiobutton-box.p-radiobutton-box.p-radiobutton-box:hover {
    border-color: var(--primary-600);
    border-width: 4px;
  }
}
.p-radiobutton-icon.p-radiobutton-icon.p-radiobutton-icon {
  scale: 0.5;
  background-color: white;
}
</style>
