<script lang="ts" setup>
// Prop과 이벤트 인터페이스 정의
interface SelectChipProps {
  label: string;
  isSelected: boolean;
}

const props = withDefaults(defineProps<SelectChipProps>(), {
  label: '',
  isSelected: false,
});
const emit = defineEmits(['toggle']);

// isActive 상태를 토글하는 함수
const emitToggle = () => {
  emit('toggle');
};
</script>

<template>
  <div
    :class="[
      'flex',
      'h-10',
      'px-4',
      'border',
      'rounded-[100px]',
      'justify-center',
      'items-center',
      'cursor-pointer',
      'text-sm',
      'font-semibold',
      isSelected
        ? 'bg-primary-100 text-primary-600 border-primary-600'
        : 'bg-neutral-000 text-neutral-1000 border-neutral-200',
    ]"
    @click="emitToggle">
    {{ props.label }}
  </div>
</template>
