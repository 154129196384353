export const ProductListOtherFilters = ['new', 'best', 'newClass', 'earlybirdSpecialPrice', 'openScheduled'];

// 70 여름 특가에서 쿠폰 적용을 막아야하는
export const nonTargetListSummerDiscount = [
  2049, 2069, 2070, 2083, 2078, 2071, 2072, 2084, 2085, 2073, 2074, 2099, 2086, 2075, 2076, 2094, 2088, 2077, 2080,
  2095, 2090, 2079, 2081, 2096, 2100, 2105, 2117, 2055, 2106, 2056, 2057, 2058, 2059, 2060, 2061, 2062, 2092, 2107,
  2108, 2110, 2109, 2093, 2097, 2063, 2098, 2064, 2065, 2066, 2067, 2111, 2113, 2114, 2115, 2087, 2091, 2089, 2101,
  2102, 2103, 2104, 2054,
];

export const discountCouponSeqForNew = [4, 207];

export const CommunityMainTabNumbers = {
  HOME: '100000',
  FOLLOW: '100001',
  MY_ACTIVITY: '100002',
  /** 전문가컬럼 */
  COLUMN: '100140',
  WEOLBU_CHALLENGE: '100141',
  HELP_WEOLBU: '100142',
  STUDY: '100143',
  INTRO_LECTURE: '100144',
};

export const CommunityMainSubTabNumbers = {
  INVESTMENT_QNA: '1',
  /** 전문가칼럼 */
  EXPERT_COLUMN: '2',
  /** 실전투자경험 */
  INVESTMENT_EXPERIENCE: '3',
  DAILY_MOKSILGAM: '8',
  /** 추천도서후기 */
  RECOMMEND_BOOK_REVIEW: '10',
  INVESTMENT_STUDY_CERTIFICATION: '11',
  HELP_WEOLBU: '12',
  WEOLBU_CHALLENGE: '33',
  INTRO_LECTURE: '34',
  /** 내집마련후기 */
  HOME_PURCHASE: '39',
  /** 초보경험담 */
  BEGINER_EXPERIENCE: '40',
  TOP_POSTS: '53',
  BEST_EXPERIENCE: '54',
  /** 첫1억달성기 */
  MILLION: '64',
  FOUR_WEEKS_CHALLENGE: '79',
};

export const HONORS_NOTICE_LINK = 'https://weolbu.com/footer/notice/47';
