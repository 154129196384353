// 강의 추천 페이지로 이동
// https://weolbu.com/class/event/69 : 내집마련
// https://weolbu.com/class/event/70 : 부동산투자
// https://weolbu.com/class/event/71 : 주식 투자
// https://weolbu.com/class/event/72 : 부업
export const interestInfo: { [key: number]: { subTitle: string; redirectUrl: string } } = {
  1: {
    subTitle: '내상황과 예산에 맞춰 내집마련하고 싶어요.',
    redirectUrl: '/class/event/69',
  },
  2: {
    subTitle: '평범한 월급쟁이가 10억 벌 수 있는 법 알고 싶어요.',
    redirectUrl: '/class/event/70',
  },
  3: {
    subTitle: '돈 벌어주는 주식 종목 고르는 법 알고 싶어요.',
    redirectUrl: '/class/event/71',
  },
  4: {
    subTitle: '하루 1시간 투자해서 월 100만원 더 벌고 싶어요.',
    redirectUrl: '/class/event/72',
  },
};
// a/b test
// 내집마련:(A: 69, B: 74)
// 부동산: (A: 70, B: 75)
// 주식: (A: 71, B: 76)
// 부업: (A: 72, B: 77)
