import { BENEPIA_ENC_PARAM_KEY } from '~/constants/browser-storage-keys';

import { benepiaService } from './service';

interface State {
  benepia: {
    authorizationCode: string | null;
    authorization: {
      userId: number;
      siteName?: string;
      siteCode: string;
    } | null;
  };
}

export const useBenepiaStore = defineStore('benepia', {
  state: (): State => ({
    benepia: {
      authorizationCode: null,
      authorization: null,
    },
  }),
  actions: {
    async init() {
      if (this.benepia.authorizationCode) {
        return { authorizationCode: this.benepia.authorizationCode, authorization: this.benepia.authorization };
      }

      const encParam = sessionStorage.getItem(BENEPIA_ENC_PARAM_KEY);
      if (!encParam) {
        console.warn('benepiaService.useBenepiaStore.actions.init, encParam is not found');
        return;
      }

      this.benepia.authorizationCode = encParam;
      try {
        const data = await benepiaService.getBenepiaAuthorization({ authorizationCode: encParam });
        this.benepia.authorization = data;
        return { authorizationCode: encParam, authorization: data };
      } catch (error) {
        console.error('benepiaService.useBenepiaStore.actions.init', error);
      }
    },
    async getBenepiaPoint(params: { userId: string; password: string; authorizationCode: string }) {
      const data = await benepiaService.getBenepiaPoint(params);
      return {
        point: data.point,
      };
    },
  },
});
