import BaseService from '~/services/BaseService';

import { type UserInterestRequestParam, type UserInterestTag } from './types';

class UserInterestTagsService extends BaseService {
  constructor() {
    super('/users');
  }

  //회원의 관심사 태그 저장
  saveUserIntereestTags({ tagIds, isApprovedMarketing, categoryId }: UserInterestRequestParam) {
    return this.postV2(`/interest-tags`, { body: { tagIds, isApprovedMarketing, categoryId }, useKotlinBaseUrl: true });
  }
  //회원의 관심사 태그 수정
  updateUserIntereestTags({ tagIds, isApprovedMarketing, categoryId }: UserInterestRequestParam) {
    return this.patchV2(`/interest-tags`, {
      body: { tagIds, isApprovedMarketing, categoryId },
      useKotlinBaseUrl: true,
    });
  }
  //회원의 관심사 태그 정보 조회
  getUserIntereestTags() {
    return this.getV2<UserInterestTag>(`/interest-tags`, { useKotlinBaseUrl: true });
  }
}

export const userInterestTagsService = new UserInterestTagsService();
