<script setup lang="ts">
import { useThrottleFn } from '@vueuse/core';
import { isEmpty } from 'lodash-es';
import { cloneDeep } from 'lodash-es';

import Tracker from '~/libs/Tracker';

import { useCommonStore } from '~/services/common';
import { type InterestTag, type InterestTagCategory } from '~/services/common';
import { interestInfo } from '~/services/interest/composable/useInterestInfo';
import { useUserProfileStore } from '~/services/profile/store';
import { useUserAuthStore } from '~/services/userAuth';
import useUserInterestTagInfo from '~/services/users/interesttags/composables/useUserInterestTagInfo';
import { useInterestStore } from '~/services/users/interesttags/store';

import { Button } from '~/components/5x';
import IconX from '~/components/5x/icons/IconX.vue';
import AdditionalUserInterestMainDialogComp from '~/components/common/dialog/AdditionalUserInterestMainDialogComp.vue';
import AdditionalUserInterestSubDialogComp from '~/components/common/dialog/AdditionalUserInterestSubDialogComp.vue';

withDefaults(defineProps<{ title?: string }>(), { title: '확인' });

const router = useRouter();
const route = useRoute();
const interestStore = useInterestStore();
const userAuthStore = useUserAuthStore();
const userProfileStore = useUserProfileStore();

const commonStore = useCommonStore();

const isLoggedIn = computed(() => !isEmpty(userAuthStore.user));
const isInterestCheckedUser = computed(() => !isEmpty(interestStore.userInterest?.category));
const isClassUserSegment = computed(() => userProfileStore.getMyProfile?.signUpPath === 'CLASS');
const isNonePurcahseUser = computed(() => userProfileStore.getMyProfile?.paymentFunnel === 'ZERO');
const interestInfoAB = computed(() => interestInfo);

const { interests, interestsPending, refreshUserInterests, saveUserInterest, updateUserInterest } =
  useUserInterestTagInfo(isLoggedIn);

//로그인 되어 있지 않거나 기존에 마케팅 동의를 하지 않은경우만 출력
const showMarketingInfo = computed(() =>
  !interestStore.getUserInterest?.isApprovedMarketing || !isLoggedIn.value ? true : false,
);
const marketingYn = ref(true);

// 메인 관심사 선택
const mainInterestSelect = reactive({ id: -1, name: '', description: '' });
// 서브 관심사 선택
const detailInterests = ref<InterestTag[]>([]);

// 현재 보여줄 화면 탭 인덱스
const currentTabIndex = computed(() => interestStore.getInterestSelectModalTabIndex);
const pushStackNumber = ref(0);

// 1번탭 -> 0번탭으로 이동
//0번탭 -> 모달창 종료
const closeModal = (e: PopStateEvent) => {
  e.preventDefault();

  pushStackNumber.value -= 1;

  if (currentTabIndex.value === 1) {
    interestStore.setInterestSelectModalTabIndex(0);
  } else {
    interestStore.setInterestPopup(false);
  }
};

//닫기 전에 임시 저장하기
const saveTempSelectedTagInfo = () => {
  interestStore.saveTempTagInfo({
    categoryId: mainInterestSelect.id,
    tags: detailInterests.value
      ?.filter((interest) => interest.select || interest.isDefault)
      .map((interest) => interest.id),
  });
  interestStore.setInterestPopup(false);

  // handleClickCloseButton(); // 임시 저장 후 현재 모달창 닫기
};

//닫기 버튼 클릭시 popstate이벤트 제거
const handleClickCloseButton = () => {
  window.removeEventListener('popstate', closeModal);

  if (pushStackNumber.value > 0) {
    history.go(-pushStackNumber.value);
  }
  pushStackNumber.value = 0;

  interestStore.setInterestSelectModalTabIndex(0);
  interestStore.setInterestPopup(false);
};

// 나중에 재사용 위해 주석처리
const save = useThrottleFn(async () => {
  try {
    Tracker['Click Button']('interest-popup-button', '저장하고 추천받기', {
      InterestTag1st: mainInterestSelect.name,
      InterestTag2nd: detailInterests.value
        .filter((interest) => interest.select || interest.isDefault)
        .map((interest) => interest.name),
      marketingYn: marketingYn.value ? 'Y' : 'N',
      version: 2,
    });

    //기존에 선택된 관심사가 있었던 경우
    if (interestStore.getUserInterest) {
      await updateUserInterest({
        categoryId: mainInterestSelect.id,
        isApprovedMarketing: marketingYn.value,
        tagIds: detailInterests.value
          .filter((interest) => interest.select || interest.isDefault)
          .map((interest) => interest.id),
      });
    } else {
      await saveUserInterest({
        categoryId: mainInterestSelect.id,
        isApprovedMarketing: marketingYn.value,
        tagIds: detailInterests.value
          .filter((interest) => interest.select || interest.isDefault)
          .map((interest) => interest.id),
      });
    }

    const selectedInterestId = mainInterestSelect.id;
    interestStore.setShowTagBoardsFetchDelayTime(2000);
    //강제고 1초동안 관심사를 찾는 느낌을 준다.

    // 관심사 태그 버튼을 통해 모달팝업이 열렸는지
    const isOpenByRelatedTagInfo = interestStore.getIsShowInterestTag;

    refreshUserInterests();

    //강의 추천 페이지로 이동
    if (selectedInterestId !== -1 && !isOpenByRelatedTagInfo) {
      // history 변경때문에 바로 라우터 호출시 작동하지 않는 것 같음.

      const redirectUrl = interestInfoAB.value[selectedInterestId]?.redirectUrl;

      if (redirectUrl) {
        setTimeout(() => {
          router.push(interestInfoAB.value[selectedInterestId]?.redirectUrl);
        }, 100);
      }

      setTimeout(() => {
        commonStore.showToast({
          text: '고객님의 관심사를 저장했어요!',
          duration: 3000,
          align: 'left',
        });
      }, 500);
    }
    handleClickCloseButton();
  } catch (error) {
    console.error(error);
  }
}, 500);

// 관심사 정보에 유저 정보 입력
const initializeUserSelect = (interestInfo: InterestTagCategory[] | null) => {
  //사전에 저장된 태그 정보가 있다면 해당 정보로 초기화 한다.
  const tempTagInfo = interestStore.getTempSelectedTagInfo;

  if (tempTagInfo) {
    const mainInterest = interestInfo?.find((interest) => interest.id === tempTagInfo.categoryId);

    if (mainInterest) {
      mainInterestSelect.id = mainInterest?.id;
      mainInterestSelect.name = mainInterest?.name;
      mainInterestSelect.description = mainInterest?.description || '';
      detailInterests.value = cloneDeep(mainInterest.tags);
      detailInterests.value.forEach((interest) => {
        interest.select = tempTagInfo.tags.includes(interest.id);
      });
    }

    interestStore.setInterestSelectModalTabIndex(1);
    interestStore.saveTempTagInfo(null);

    return;
  }

  if (!interestInfo) {
    return;
  }

  const mainInterest = interestInfo?.find((interest) => interest.select);

  //선택한 대주제가 있으면
  if (mainInterest) {
    mainInterestSelect.id = mainInterest?.id;
    mainInterestSelect.name = mainInterest?.name;
    mainInterestSelect.description = mainInterest?.description || '';
    detailInterests.value = cloneDeep(mainInterest.tags);

    // 세부 주제 선택한 것이 있으면 2번쨰 탭을 띄운다.
    if (mainInterest.tags?.length > 0) {
      interestStore.setInterestSelectModalTabIndex(1);
    }
  } else {
    mainInterestSelect.id = -1;
  }
};

//모달이 새로 켜지면 관심사 정보를 초기화 한다.
watch(
  () => interestStore.getInterestPopupVisible,
  (value) => {
    if (value) {
      refreshUserInterests();
    }
  },
);

//모달을 보여줄지를 결정한다.
const handleVisible = computed<boolean>({
  get: () => {
    // 관심사 목록이 로드되지 않았으면 노출하지 않는다.
    if (interestsPending.value || !interests.value || interests.value?.length <= 0) {
      return false;
    }

    // 회원가입 페이지에서는 노출하지 않는다. & 이벤트 페이지에서는 노출하지 않는다.
    if (route.path === '/signup' || route.path.startsWith('/class/event')) {
      return false;
    }

    if (
      isLoggedIn.value && // 로그인되어 있으면서
      route.path === '/' && // 메인페이지라면,
      !isInterestCheckedUser.value && // 관심사가 선택되지 않았으며
      isClassUserSegment.value && // CLASS 세그먼트이며
      isNonePurcahseUser.value // 첫구매를 하지 않은 유저인 경우에 노출한다.
    ) {
      interestStore.interestPopupVisible = true;
    }

    return interestStore.interestPopupVisible;
  },
  set: (val) => {
    interestStore.setInterestPopup(val);
  },
});

/**
 * 노출되었을 때,
 * 트래킹과
 * 브라우저 뒤로가기하면 모달 끄는 이벤트를 추가한다.
 */
watch(
  () => handleVisible.value,
  async (visible) => {
    if (visible) {
      initializeUserSelect(interests.value);
      if (interestStore.getInterestSelectModalTabIndex === 1) {
        history.pushState(null, '', location.href);
        history.pushState(null, '', location.href);
        pushStackNumber.value += 2;
      } else {
        history.pushState(null, '', location.href);
        pushStackNumber.value += 1;
      }
      window.addEventListener('popstate', closeModal);
      Tracker['View Popup']({ popupType: 'interest-popup' });
      return;
    }

    interestStore.setIsShowInterestTag(false);
    window.removeEventListener('popstate', closeModal);
  },
  { immediate: true },
);

// 메인 관심사 선택
const handleChangeInteresetSelect = (interestId: number) => {
  const selectedInterestInfo = interests.value?.find((interest) => interest.id === interestId);

  mainInterestSelect.id = interestId;
  mainInterestSelect.name = selectedInterestInfo?.name || '';
  mainInterestSelect.description = selectedInterestInfo?.description || '';
  if (selectedInterestInfo?.tags) {
    detailInterests.value = cloneDeep(selectedInterestInfo?.tags);
  } else {
    detailInterests.value = [];
  }
};

// 마케팅 동의 여부 선택
const handleChangeMarketingYn = (marketingvalue: boolean) => {
  marketingYn.value = marketingvalue;
};

//뒤로가기 대응을 위해 pushstate
const nextTab = () => {
  history.pushState(null, '', location.href);
  pushStackNumber.value += 1;
  interestStore.setInterestSelectModalTabIndex(1);
};

// 이전으로 버튼 클릭시 : 뒤로가기 버튼 클릭과 동일
const goPrevModal = () => {
  history.go(-1);
};

//세부 관심사 선택
const handleSelectDetailInterest = (detailInterestId: number) => {
  const currentTagIndex = detailInterests.value.findIndex((value) => value.id === detailInterestId);
  if (currentTagIndex !== -1) {
    detailInterests.value[currentTagIndex].select = !detailInterests.value[currentTagIndex].select;
  }
};
</script>

<template>
  <Dialog
    v-model:visible="handleVisible"
    :header="title"
    :breakpoints="{ '1199px': '500px', '960px': '100dvw', '640px': '100dvw' }"
    :modal="true"
    :closable="false"
    :draggable="false"
    :block-scroll="false"
    :base-z-index="2000"
    :auto-z-index="true"
    class="additional-interest-dialog">
    <template #header>
      <div class="flex flex-col">
        <Button
          v-if="interestStore.isCanClose"
          class="md:absolute md:top-6 md:right-6 text-neutral-500 mb-4 md:mb-0"
          variant="ghost"
          color="neutral"
          size="icon-sm"
          @click="handleClickCloseButton">
          <IconX size="24" />
        </Button>

        <div
          v-if="currentTabIndex === 0"
          class="text-neutral-1000 text-2xl font-bold md:text-center md:text-3xl mt-6 md:mt-0">
          고객님에게 맞는<br />강의와 콘텐츠를 추천해드려요.
        </div>
        <div
          v-else-if="currentTabIndex === 1"
          class="text-neutral-1000 text-2xl font-bold md:text-center md:text-3xl mt-6 md:mt-0">
          세부 주제를 선택해 주세요
        </div>
      </div>
    </template>

    <AdditionalUserInterestMainDialogComp
      v-if="currentTabIndex === 0 && interests"
      :show-marketing-checkbox="showMarketingInfo"
      :interests="interests"
      :interest-select="mainInterestSelect.id"
      :marketing="marketingYn"
      :interest-info="interestInfo"
      @update:interesetSelect="handleChangeInteresetSelect"
      @update:marketing="handleChangeMarketingYn" />

    <AdditionalUserInterestSubDialogComp
      v-else-if="currentTabIndex === 1"
      :is-logged-in="isLoggedIn"
      :main-interest="mainInterestSelect"
      :detail-interests="detailInterests"
      @update:save-temp-selected-tag-info="saveTempSelectedTagInfo"
      @update:toggle-detail-interest="handleSelectDetailInterest" />

    <template #footer>
      <div v-if="currentTabIndex === 0">
        <Button
          class="w-full"
          color="primary"
          variant="solid"
          size="xl"
          :disabled="mainInterestSelect.id === -1"
          @click="nextTab"
          >다음으로</Button
        >
      </div>
      <div v-else-if="currentTabIndex === 1" class="flex gap-2">
        <Button class="w-full" color="neutral" variant="outline" size="xl" @click="goPrevModal">이전으로</Button>
        <Button
          class="w-full"
          color="primary"
          variant="solid"
          size="xl"
          :disabled="detailInterests?.filter((value) => value.select && !value.isDefault)?.length < 2"
          @click="save"
          >저장하고 추천받기</Button
        >
      </div>
    </template>
  </Dialog>
</template>

<style lang="scss">
.additional-interest-dialog {
  width: 500px;
  @media screen and (max-width: $md) {
    min-width: 100dvw;
    width: 100%;
    margin: 0 !important;
    max-height: 100% !important;
    height: 100dvh;
  }
  .p-dialog-header {
    padding: 44px 40px 0 40px;
    display: flex;
    justify-content: center;
    position: relative;
    @media screen and (max-width: $md) {
      justify-content: flex-start;
      // border-bottom: 1px solid #eee;
      border: 0;
      padding: 24px 16px 0 16px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
  .p-dialog-content {
    padding: 0;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 0;
    @media screen and (max-width: $md) {
      height: calc(var(--vh, 1vh) * 100 - 105px);
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .p-dialog-footer {
    padding: 0px 40px 44px;
    @media screen and (max-width: $md) {
      padding: 0px 16px 16px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
</style>
