import { now } from '~/libs/dateUtils';
import { safeLocalStorage } from '~/libs/safeLocalStorage';

import { myPageService } from '~/services/mypage';
import { useUserProfileStore } from '~/services/profile';
import { useUserAuthStore } from '~/services/userAuth';
import { userCreatorService } from '~/services/userCreator/service';

type Days = number;
type TGetCreatorInviteCodeMappingType = keyof typeof getCreatorInviteCodeMapping;

export const CREATOR_UTM_SOURCE = 'creator';
export const CREATOR_UTM_MEDIUM = 'referral';
export const CREATOR_UTM_CAMPAIGN = 'creator_share_button';
export const CREATOR_STORAGE_KEY = 'content-invite'; // TODO : shareDialog 리팩토링 이후 내부에서만 쓰는 상수로 변경
const STORAGE_MAX_AGE = 3;

const getCreatorInviteCodeMapping = {
  class: userCreatorService.getClassCreatorReferralCode.bind(userCreatorService),
  community: userCreatorService.getCommunityCreatorReferralCode.bind(userCreatorService),
};

const useUserInviteCode = () => {
  const route = useRoute();
  const router = useRouter();
  const userAuthStore = useUserAuthStore();

  const setStorageWithMaxAge = <T>(key: string, value: T, maxAge: Days) => {
    const data = { expires: now().add(maxAge, 'days').format('YYYY-MM-DD'), value };
    safeLocalStorage.setItem(key, JSON.stringify(data));
  };

  const getValidStorageData = <T>(key: string): T | null => {
    const data = JSON.parse(safeLocalStorage.getItem(key) || 'null');
    if (!data || isExpired(data.expires)) {
      safeLocalStorage.removeItem(key);
      return null;
    }
    return data.value as T;
  };

  const isExpired = (expires: string | null | undefined): boolean => {
    if (!expires) {
      return true;
    }
    return now().startOf('day').isAfter(expires);
  };

  const profileStore = useUserProfileStore();

  const userInviteCode = computed(() => {
    if (!profileStore.profile) {
      return '';
    }

    return profileStore.profile.myInviteCode;
  });

  const checkIsCreatorInviteCode = async (type: TGetCreatorInviteCodeMappingType, contentId?: string | number) => {
    try {
      if (!contentId || !userAuthStore.user || userAuthStore?.user?.creatorYn !== 'Y') {
        return false;
      }

      const { data } = await getCreatorInviteCodeMapping[type](String(contentId));

      const referralCode = data.items?.referralCode;

      if (!referralCode) {
        return false;
      }

      return referralCode === userInviteCode.value;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const getUserInviterCode = () => {
    const searchParams = getValidStorageData<{ inviteCode: string }>(CREATOR_STORAGE_KEY);

    return searchParams?.inviteCode || '';
  };

  const getReferralCode = () => {
    return route?.query.referralCode || null;
  };

  const removeUserInviterCode = () => {
    safeLocalStorage.removeItem(CREATOR_STORAGE_KEY);
  };

  onMounted(() => {
    if (route.query?.inviteCode && !userAuthStore.user) {
      // 초대 코드가 존재하고, 사용자가 로그인하지 않은 경우에 초대 코드를 저장
      setStorageWithMaxAge(CREATOR_STORAGE_KEY, { inviteCode: route.query.inviteCode }, STORAGE_MAX_AGE);
    }
  });

  return {
    userInviteCode,
    getReferralCode,
    checkIsCreatorInviteCode,
    getUserInviterCode,
    removeUserInviterCode,
  };
};

export default useUserInviteCode;
