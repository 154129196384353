import { type InterestTagCategory } from '~/services/common/type';

import type { ApiResponse } from '~/types/global';

import BaseService from '../BaseService';

class InterestTagMetaInfoService extends BaseService {
  constructor() {
    super('/interest-tags');
  }

  getInterestTagList() {
    return this.getV2<InterestTagCategory[]>('/categories', {
      useKotlinBaseUrl: true,
    });
  }
}

export const interestTagMetaInfoService = new InterestTagMetaInfoService();
