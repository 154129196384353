import qs from 'qs';

import Tracker from '~/libs/Tracker';
import { now } from '~/libs/dateUtils';
import { safeLocalStorage } from '~/libs/safeLocalStorage';

import { useBenepiaStore } from '~/services/benepia';
import type { SignupPathType } from '~/services/userAuth';

export type FunnelType = {
  inflow: {
    utm_source: string | null;
    utm_medium: string | null;
    utm_campaign: string | null;
    utm_content: string | null;
    utm_term: string | null;
    utm_path: SignupPathType | null;
    utm_resource: number | string | null;
    utm_entry_at: string | null; // YYYY-MM-DDThh:mm:ss 형식
  };
};

export const getPathResource = (url: string): { path: SignupPathType; resource: number | string | null } => {
  if (!$isClient()) {
    return { path: 'ETC', resource: null };
  }

  const benepiaStore = useBenepiaStore();
  if (benepiaStore.benepia.authorizationCode) {
    return { path: 'BENEPIA', resource: null };
  }

  let pathname: string;
  let search: string;

  // full URL이 들어올 경우
  if (url.startsWith('http://') || url.startsWith('https://')) {
    // 전체 URL이 들어오면 그대로 사용
    const { pathname: fullPathname, search: fullSearch } = new URL(url);
    pathname = fullPathname;
    search = fullSearch;
  } else {
    // 상대 URL이면 현재 origin을 기준으로 절대 URL로 변환
    const { pathname: basePathname, search: baseSearch } = new URL(url, window.location.origin);
    pathname = basePathname;
    search = baseSearch;
  }

  const resources = ['/product/', '/product', '/community/', '/profile/', '/class/event/'] as const;
  const hasResource = resources.find((resource) => pathname.startsWith(resource));

  if (!hasResource) {
    if (pathname === '/') {
      return { path: 'MAIN', resource: null };
    }

    if (pathname === '/community') {
      return { path: 'COMMUNITY', resource: null };
    }

    if (pathname === '/product') {
      return { path: 'CLASS', resource: null };
    }

    return { path: 'ETC', resource: null };
  }

  switch (hasResource) {
    case '/community/': {
      const match = pathname.match(/\/community\/([a-zA-Z0-9]+)/);
      return { path: 'COMMUNITY', resource: match ? match[1] : null };
    }
    case '/class/event/': {
      const match = pathname.match(/\/class\/event\/([a-zA-Z0-9]+)/);
      return { path: 'EVENT', resource: match ? match[1] : null };
    }
    case '/product/': {
      const match = pathname.match(/\/product\/([a-zA-Z0-9]+)/);
      return { path: 'CLASS', resource: match ? match[1] : null };
    }
    case '/product': {
      const params = qs.parse(search, { ignoreQueryPrefix: true }); // qs를 사용하여 파싱
      const displaySeq = params.displaySeq as string | undefined;
      return { path: 'CLASS', resource: displaySeq || null };
    }
    case '/profile/': {
      const match = pathname.match(/\/profile\/([a-zA-Z0-9]+)/);
      return { path: 'PROFILE', resource: match ? match[1] : null };
    }
  }
};

export const getFunnelData = (funnelKey: keyof FunnelType) => {
  const funnel = safeLocalStorage.getItem(funnelKey) as FunnelType[typeof funnelKey];
  return (
    funnel || {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      utm_content: null,
      utm_term: null,
      utm_path: null,
      utm_resource: null,
      utm_entry_at: null,
    }
  );
};

export const memoFunnelData = (funnelKey: keyof FunnelType) => {
  if (!$isClient()) {
    return;
  }
  const searchParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const hasNewUtm = !!searchParams.utm_source;
  if (!hasNewUtm) {
    return;
  }

  const prevFunnelData = getFunnelData(funnelKey);

  const utm_source = searchParams.utm_source || prevFunnelData?.utm_source || null;
  const utm_medium = hasNewUtm ? searchParams.utm_medium || null : null;
  const utm_campaign = hasNewUtm ? searchParams.utm_campaign || null : null;
  const utm_content = hasNewUtm ? searchParams.utm_content || null : null;
  const utm_term = hasNewUtm ? searchParams.utm_term || null : null;

  const { path: utm_path, resource: utm_resource } = getPathResource(window.location.href);
  const utm_entry_at = (hasNewUtm ? now().format('YYYY-MM-DDTHH:mm:ss') : prevFunnelData?.utm_entry_at) || null;

  const targetInfo = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_content,
    utm_term,
    utm_path,
    utm_resource,
    utm_entry_at,
  } as FunnelType[typeof funnelKey];

  Tracker['UTM Tagged Landing'](targetInfo);
  safeLocalStorage.setItem(funnelKey, targetInfo);
};
