import { interestTagMetaInfoService } from '~/services/interesttags/service';

import { type InterestTagCategory } from './../../common/type';
import { userInterestTagsService } from './services';
import type { UserInterestRequestParam, UserInterestTag } from './types';

declare global {
  interface Window {
    openInterest: () => void;
  }
}

interface State {
  interestPopupVisible: boolean; // 팝업창 노출 여부
  interesetSelectTabIndex: number; // 몇번째 탭 보여주는지
  interestMeta: InterestTagCategory[]; // 모든 관심사 태그 정보
  userInterest?: UserInterestTag; // 유저가 선택한 관심사 태그 정보
  tagBoardsFetchDelayTime: number; // 태그 보드 렌더링 딜레이 시간
  tempSelectedTagInfo: { categoryId: number; tags: number[] } | null; // 임시 저장 관심사 태그 정보 (로그인, 회원가입시 사용)
  isShowFromUserInterestTag: boolean; // 관심사 태그 버튼을 통해 노출된건지 여부(해당버튼으로 노출시에만 닫기 버튼 존재)
  isCanClose: boolean; // 닫을 수 있는 모달을 띄울지
}

// 사용자 관심사 및 추가 정보
export const useInterestStore = defineStore('user-interest-tags', {
  state: (): State => ({
    interestPopupVisible: false,
    interestMeta: [],
    tagBoardsFetchDelayTime: 0,
    interesetSelectTabIndex: 0,
    tempSelectedTagInfo: null,
    isShowFromUserInterestTag: false,
    isCanClose: false,
  }),
  actions: {
    async fetchInterestMeta() {
      try {
        const res = await interestTagMetaInfoService.getInterestTagList();

        if (!res) {
          return [];
        }
        this.interestMeta = res;

        const {
          public: { app_env },
        } = useRuntimeConfig();
        if (app_env !== 'prod') {
          window.openInterest = () => this.setInterestPopup(true);
        }

        this.interestMeta = res;
        return res;
      } catch (err) {
        console.error('err :>> ', err);
      }
    },
    async fetchMyInterest() {
      const data = await userInterestTagsService.getUserIntereestTags();

      this.userInterest = data;
      return data;
    },

    async saveMyInterestTags(params: UserInterestRequestParam) {
      await userInterestTagsService.saveUserIntereestTags(params);
    },

    async updateMyInterestTags(params: UserInterestRequestParam) {
      await userInterestTagsService.updateUserIntereestTags(params);
    },
    setInterestPopup(visible: boolean, showFromUserInterestTag = false, isCanClose = false) {
      const {
        public: { app_env },
      } = useRuntimeConfig();

      this.interestPopupVisible = visible;
      this.isShowFromUserInterestTag = showFromUserInterestTag;
      this.isCanClose = isCanClose;
    },
    setShowTagBoardsFetchDelayTime(time: number) {
      this.tagBoardsFetchDelayTime = time;
    },
    setInterestSelectModalTabIndex(index: number) {
      this.interesetSelectTabIndex = index;
    },
    saveTempTagInfo(props: { categoryId: number; tags: number[] } | null) {
      if (!props) {
        this.tempSelectedTagInfo = null;
      }

      this.tempSelectedTagInfo = props;
    },

    setIsShowInterestTag(isShow: boolean) {
      this.isShowFromUserInterestTag = isShow;
    },
  },
  getters: {
    getInterestPopupVisible(state: State): any {
      return state.interestPopupVisible;
    },
    getInterestMeta(state: State): InterestTagCategory[] {
      return state.interestMeta;
    },
    getUserInterest(state: State): UserInterestTag | undefined {
      return state.userInterest;
    },
    getShowTagBoardsFetchDelayTime(state: State): number {
      return state.tagBoardsFetchDelayTime;
    },
    getInterestSelectModalTabIndex(state: State): number {
      return state.interesetSelectTabIndex;
    },
    getTempSelectedTagInfo(state: State): { categoryId: number; tags: number[] } | null {
      return this.tempSelectedTagInfo;
    },
    getIsShowInterestTag(state: State): boolean {
      return this.isShowFromUserInterestTag;
    },
  },
});
