export const NEXT_MIGRATED_PATHNAME_REGEXPS: RegExp[] = [
  /^\/class\/event\/(\d+)(\/)?/,
  /^\/mypage.*$/,
  /^\/footer.*$/,
  /^\/profile\/(\d+)(\/)?/,
  /^\/search.*$/,
  /^\/find.*$/,
  /^\/zero\/([a-zA-Z0-9]+)(\/)?/,
  /^\/class.*$/,
  /^\/community\/(\d+)(\/)?/,
];
export const NEXT_MIGRATED_PATHNAMES: Set<string> = new Set(['/class/event', '/notifications', '/community', '/curriculum']);
