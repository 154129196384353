import BaseService from '../BaseService';

class BenepiaService extends BaseService {
  constructor() {
    super('/benepia');
  }
  // 베네피아 인증 사용자 조회 API
  getBenepiaAuthorization(params: { authorizationCode: string }) {
    return this.getV2<{ userId: number; siteName: string; siteCode: string }>(`/authorization`, {
      query: { ...params },
      useKotlinBaseUrl: true,
    });
  }
  // 베네피아 포인트 조회 API
  getBenepiaPoint(params: { userId: string; password: string; authorizationCode: string }) {
    return this.postV2<{ point: number }>(`/point`, {
      body: { ...params },
      useKotlinBaseUrl: true,
    });
  }
}

export const benepiaService = new BenepiaService();
